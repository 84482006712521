import "./CharacterSwitchUi.css";
import { motion } from "framer-motion";
import { statePlayerSwitch } from "../store";
import { useState } from "react";


export default function CharacterSwitchUi() {
  return <CharacterSwitchUiKon />;
}

function CharacterSwitchUiKon() {



  function handleCharClick() {
    if (!statePlayerSwitch.playerWoman) {
      statePlayerSwitch.playerWoman = true;
    } else {
      statePlayerSwitch.playerWoman = false;
    }

    
  }



  return (
    <motion.div
      className="containerCharSwitch"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", duration: 1.8, delay: 1 }}
    >
    
      



          <div
            className="circleSwitch"
            onClick={handleCharClick}
          >
            <img
              className="arrowIcon"
              src="UI/arrowLeft.svg"
              alt="CharacterSwitch"
            />
           
          </div>
          <h1 className="textContainer">Choose your avatar</h1>
          <div
            className="circleSwitch"
            onClick={handleCharClick}
          >
            <img
              className="arrowIcon"
              src="UI/arrowRight.svg"
              alt="CharacterSwitch"
            />
           
          </div>
       
          
         
    </motion.div>
  );
}
