import * as THREE from 'three'

export default [


{
	Id: 'CAM_ThemeM_Handelswege',
	Titel: 'CAM_ThemeM_Handelswege',
	Position: new THREE.Vector3 (-138.132, 27.727, 105.185),
	Rotation: new THREE.Vector3 (85.0565,-30.6812,-177.473)
},
{
	Id: 'CAMTARGET_ThemeM_Handelswege',
	Titel: 'CAMTARGET_ThemeM_Handelswege',
	Position: new THREE.Vector3 (-155.489, 25.2301, 134.648),
	Rotation: new THREE.Vector3 (-2.55505e-05,-4.8618e-05,-60.0)
},
{
	Id: 'CAM_ThemeM_Holzarbeit',
	Titel: 'CAM_ThemeM_Holzarbeit',
	Position: new THREE.Vector3 (138.132, 27.727, -105.186),
	Rotation: new THREE.Vector3 (-85.0565,30.6812,2.52712)
},
{
	Id: 'CAMTARGET_ThemeM_Holzarbeit',
	Titel: 'CAMTARGET_ThemeM_Holzarbeit',
	Position: new THREE.Vector3 (155.489, 25.23, -134.648),
	Rotation: new THREE.Vector3 (-1.47878e-06,8.53773e-07,120.0)
},
{
	Id: 'CAM_ThemeM_Klima',
	Titel: 'CAM_ThemeM_Klima',
	Position: new THREE.Vector3 (-22.0275, 27.727, -172.218),
	Rotation: new THREE.Vector3 (-85.1327,-29.1369,-2.37423)
},
{
	Id: 'CAMTARGET_ThemeM_Klima',
	Titel: 'CAMTARGET_ThemeM_Klima',
	Position: new THREE.Vector3 (-38.8638, 25.23, -201.981),
	Rotation: new THREE.Vector3 (-1.47878e-06,-8.53774e-07,60.0)
},
{
	Id: 'CAM_ThemeM_Menschen',
	Titel: 'CAM_ThemeM_Menschen',
	Position: new THREE.Vector3 (22.0275, 27.727, 172.218),
	Rotation: new THREE.Vector3 (85.1327,29.1369,177.626)
},
{
	Id: 'CAMTARGET_ThemeM_Menschen',
	Titel: 'CAMTARGET_ThemeM_Menschen',
	Position: new THREE.Vector3 (38.8638, 25.23, 201.981),
	Rotation: new THREE.Vector3 (1.47878e-06,8.53774e-07,-120.0)
},
{
	Id: 'CAM_ThemeM_MuseenDerRegion',
	Titel: 'CAM_ThemeM_MuseenDerRegion',
	Position: new THREE.Vector3 (160.159, 27.727, 67.0327),
	Rotation: new THREE.Vector3 (10.3152,85.6799,100.344)
},
{
	Id: 'CAMTARGET_ThemeM_MuseenDerRegion',
	Titel: 'CAMTARGET_ThemeM_MuseenDerRegion',
	Position: new THREE.Vector3 (194.353, 25.23, 8000),
	Rotation: new THREE.Vector3 (0.0,1.70755e-06,180.0)
},
{
	Id: 'CAM_ThemeM_BuehlerVideo',
	Titel: 'CAM_ThemeM_BuehlerVideo',
	Position: new THREE.Vector3 (-160, 20, -150),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_ThemeM_BuehlerVideo',
	Titel: 'CAMTARGET_ThemeM_BuehlerVideo',
	Position: new THREE.Vector3 (-199.395, 20, -150),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_InfoPoint',
	Titel: 'CAMTARGET_InfoPoint',
	Position: new THREE.Vector3 (185.213, 8.25801, 61.6181),
	Rotation: new THREE.Vector3 (0.0,-1.46876e-05,-7.5639e-06)
},
{
	Id: 'CAM_InfoPoint',
	Titel: 'CAM_InfoPoint',
	Position: new THREE.Vector3 (10.9132, 76.2301, -113.331),
	Rotation: new THREE.Vector3 (69.9999,-0.152994,-179.944)
},
{
	Id: 'CAM_Landscape',
	Titel: 'CAM_Landscape',
	Position: new THREE.Vector3 (621.381, 84.8287, -9.85199),
	Rotation: new THREE.Vector3 (126.38,88.1975,-143.634)
},
{
	Id: 'CAMTARGET_Landscape',
	Titel: 'CAMTARGET_Landscape',
	Position: new THREE.Vector3 (783.618, 87.8586, -2.42705),
	Rotation: new THREE.Vector3 (0.0,90.0,0.0)
},
//InfoMonitor

{
	Id: 'CAM_InfoPointMonitor001',
	Titel: 'CAM_InfoPointMonitor001',
	Position: new THREE.Vector3 (232.2, 104.763, -162.912),
	Rotation: new THREE.Vector3 (52.0536,-0.964309,-179.248)
},
{
	Id: 'CAMTARGET_InfoPointMonitor001',
	Titel: 'CAMTARGET_InfoPointMonitor001',
	Position: new THREE.Vector3 (246.843, 0.0, -3.90664),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_InfoPointMonitor002',
	Titel: 'CAM_InfoPointMonitor002',
	Position: new THREE.Vector3 (-11.0773, 91.1386, -189.478),
	Rotation: new THREE.Vector3 (52.0536,-0.964309,-179.248)
},
{
	Id: 'CAMTARGET_InfoPointMonitor002',
	Titel: 'CAMTARGET_InfoPointMonitor002',
	Position: new THREE.Vector3 (2.69722, 0.0, 0.0),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_InfoPointMonitor003',
	Titel: 'CAM_InfoPointMonitor003',
	Position: new THREE.Vector3 (-203.521, 104.457, -170.326),
	Rotation: new THREE.Vector3 (52.0536,-0.964309,-179.248)
},
{
	Id: 'CAMTARGET_InfoPointMonitor003',
	Titel: 'CAMTARGET_InfoPointMonitor003',
	Position: new THREE.Vector3 (-200.313, 0.0, 0.0),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
]
