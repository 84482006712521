import "./ToolBar.css";
import { motion, AnimatePresence } from "framer-motion";
import stateOrbitCamPos from "../EntryRoom/states/stateOrbitCamPos.jsx";
import { stateOrbitLocation } from "../store";
import { stateInArea } from "../store";
import { useSnapshot } from "valtio";
import { stateUiDisplay } from "../store";
import InfoWindow from "./InfoWindow";
import textData from "../EntryRoom/text/entryRoomText.json";
import { useEffect, useState } from "react";

// import { stateIndex } from "../store";

export default function ToolBar() {
  const snapInArea = useSnapshot(stateInArea);
  const snapUiDisplay = useSnapshot(stateUiDisplay);
  const [matchingText, setMatchingText] = useState();
  const [hintText, setHintText] = useState("");
  const [matchingOrbit, setMatchingOrbit] = useState();

  //Check if InfoWindow has Text
  useEffect(() => {
    const textCurrentIndex = snapInArea.inAreaIndex;

    const findObjectById = (id) => {
      return textData.find((item) => item.Id === id) || null;
    };

    const matchingTextKon = findObjectById(textCurrentIndex);

    setMatchingText(matchingTextKon);

    if (matchingTextKon) {
      stateUiDisplay.storyTextMatch = true;
      setHintText(matchingTextKon.Hint);
    } else {
      stateUiDisplay.storyTextMatch = false;
      setHintText("");
    }
  }, [snapInArea]);

  //Check if Orbit has Data
  useEffect(() => {
    const textCurrentIndex = snapInArea.inAreaIndex;

    const findObjectById = (id) => {
      return (
        stateOrbitCamPos.find((item) => item.Id.replace("CAM_", "") === id) ||
        null
      );
    };

    const matchingOrbitKon = findObjectById(textCurrentIndex);

    if (matchingOrbitKon) {
      setMatchingOrbit(true);
    } else {
      setMatchingOrbit(false);
    }
  }, [snapInArea]);

  return (
    <>
      <AnimatePresence>
        {snapInArea.inArea ? (
          <ToolBarKon
            matchingText={matchingText}
            hintText={hintText}
            matchingOrbit={matchingOrbit}
          />
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {snapInArea.inArea &&
        snapUiDisplay.storyTextMatch &&
        snapUiDisplay.openInfoWindow ? (
          <InfoWindow matchingText={matchingText} />
        ) : null}
      </AnimatePresence>
    </>
  );
}

function ToolBarKon({ matchingText, matchingOrbit, hintText }) {
  const snapUiDisplay = useSnapshot(stateUiDisplay);
  const snapLerpRuns = useSnapshot(stateOrbitLocation);
  const snapInArea = useSnapshot(stateInArea);
  const orbitCurrentIndex = snapInArea.inAreaIndex;

  const [hintVisible, setHintVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [storyClicked, setStoryClicked] = useState(false);
  // const [hintText, setHintText] = useState("")

  useEffect(() => {
    if (snapUiDisplay.storyTextMatch) {
      const hintTimeout = setTimeout(() => {
        setHintVisible(false);
      }, 7500); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(hintTimeout); // Clean up the timer on unmount
    }
  }, [snapUiDisplay.storyTextMatch]);

  //Check ID
  const findObjectById = (id) => {
    return (
      stateOrbitCamPos.find((item) => item.Id.replace("CAM_", "") === id) ||
      null
    );
  };

  //Check ID
  const findTargetById = (id) => {
    return (
      stateOrbitCamPos.find(
        (item) => item.Id.replace("CAMTARGET_", "") === id
      ) || null
    );
  };

  const matchingObject = findObjectById(orbitCurrentIndex);
  const matchingTarget = findTargetById(orbitCurrentIndex);

  // useEffect(()=>{

  //   if(!snapLerpRuns.lerpRuns)
  //   {
  //    stateOrbitLocation.orbitClicked = !stateOrbitLocation.orbitClicked
  // }
  // },[snapLerpRuns.lerpRuns])

  function handleToggleOrbitView() {
    if (!snapLerpRuns.lerpRuns) {
      stateOrbitLocation.orbitClicked = !stateOrbitLocation.orbitClicked;
      setClicked(!clicked);

      if (matchingObject) {
        stateOrbitLocation.CameraPos.set(...matchingObject.Position);
        stateOrbitLocation.TargetPos.set(...matchingTarget.Position);
      }

      // Toggle the orbitClicked state
      // stateOrbitLocation.orbitClicked = !stateOrbitLocation.orbitClicked
    }
  }

  useEffect(()=>{

    if(stateUiDisplay.openInfoWindow){
    setStoryClicked(true)
  }
  else if(!stateUiDisplay.openInfoWindow)
  {
    setStoryClicked(false)
  }
  },[stateUiDisplay.openInfoWindow])

  function handleToggleStoryWindow() {

    stateUiDisplay.openInfoWindow = !stateUiDisplay.openInfoWindow;

  }

  return (
    <>
    
      <div className="hint">{hintText}</div>
      {snapInArea.inArea && (
        <motion.div
          className="ToolbarContainer"
          initial={{  x: -80 }}
          animate={{  x: 0 }}
          exit={{  x: -80  }} // Define exit animation here
          transition={{ type: "spring", duration: 1.3, delay: 0 }}
        >
          
            {matchingOrbit ? (
              <motion.div
                className={`circleToolbar ${clicked ? "clicked" : ""}`}
                animate={{
                  // speed:0.1,
                  scale: [1, 1, 1.3, 1.3, 1],
                  // rotate: [0, 360, 360, 360, 0],
                  // borderRadius: ["50%", "0%", "0%", "0%", "50%"],
                  backgroundColor: [
                    "rgba(0, 0, 0, 0.163)",
                    "rgba(0, 168, 156)",
                    "rgba(0, 168, 156)",
                    "rgba(0, 0, 0, 0.163)",
                  ],
                }}
                transition={{
                  duration: 0.65,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeat: 1,
                }}
              >
                <img
                  className="ToolBarImages"
                  src="../UI/OrbitButton.png"
                  alt="Orbit Controls"
                  onClick={handleToggleOrbitView}
                />
                  {hintVisible && <div className="hintStatic">Orbit</div>}
              
              </motion.div>
            ) : null}
            {snapUiDisplay.storyTextMatch ? (
              <motion.div
                className={`circleToolbar ${storyClicked ? "clicked" : ""}`}
                animate={{
                  // speed:0.1,
                  scale: [1, 1, 1.3, 1.3, 1],
                  // rotate: [0, 360, 360, 360, 0],
                  // borderRadius: ["50%", "0%", "0%", "0%", "50%"],
                  backgroundColor: [
                    "rgba(0, 0, 0, 0.163)",
                    "rgba(0, 168, 156)",
                    "rgba(0, 168, 156)",
                    "rgba(0, 0, 0, 0.163)",
                  ],
                }}
                transition={{
                  duration: 0.65,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeat: 1,
                  // repeatDelay: 1
                  delay: 1.5,
                }}
                onAnimationComplete={() => setHintVisible(true)}
              >
                <img
                  className="ToolBarImages"
                  src="../UI/StoryButton.png"
                  alt="Orbit Controls"
                  onClick={handleToggleStoryWindow}
                />
                 {hintVisible && <div className="hintStaticStory ">Info</div>}
                {/* {hintVisible && <div className="hint">{matchingText.Hint}</div>} */}
              </motion.div>
            ) : null}
      
        </motion.div>
      )}
    </>
  );
}
