

import { Environment,} from "@react-three/drei"


import { useState, useRef, } from 'react'




export default function Lights()
{


const directionalLightRef = useRef()
const ambientLightRef = useRef()



const test = true

const initialLightSettings = {
  directionalIntensity: 0.5,
  ambientIntensity: 0.75,
  pointIntensity:0
  
  };

const [lightSettings, setLightSettings] = useState(initialLightSettings);
const [tweenEnds, setTweenEnds] = useState(true);




    return<>
 
      

       
   
       
<Environment 
    // preset = {"studio"}
    // ground={{ height: 35, radius: 500, scale: 3000 }}
    background = {false}
    intensity={1}
    resolution={1024}
    color="black" // (optional = white)

  
    files= "./overallTextures/industrial_room.hdr"
   
   /> 



   
{/* <directionalLight
 ref={directionalLightRef}
    intensity={lightSettings.directionalIntensity}
   position={[30,40,60]}
   shadow={false}

    /> */}

   {/* <ambientLight
     ref={ambientLightRef}
        intensity={lightSettings.ambientIntensity}
        shadow={false}
      />  */}





         


      
    

    


 

 
    </>
}

