import React, { createContext, useContext, useState } from 'react';
import * as THREE from "three";

const PlayerPositionContext = createContext();

export function PlayerPositionProvider({ children }) {
  const [playerPosition, setPlayerPosition] = useState(new THREE.Vector3(0, 0, 0));
  const [camTarget, setCamTarget] = useState(new THREE.Vector3(0, 0, 0));
  const [playerQuat, setPlayerQuat] = useState(new THREE.Quaternion(0,0,0));

  return (
    <PlayerPositionContext.Provider value={{ playerPosition, setPlayerPosition,camTarget, setCamTarget,playerQuat,setPlayerQuat }}>
      {children}
    </PlayerPositionContext.Provider>
  );
}

export function usePlayerPosition() {
  return useContext(PlayerPositionContext);
}