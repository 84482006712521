import * as THREE from 'three'

export default [
	{
		Id: 'ThemeM_BuehlerVideo',
		Titel: 'ThemeM_BuehlerVideo',
		Position: new THREE.Vector3 (-199.395, 20, -150),
		Rotation: new THREE.Vector3 (0.0,0.0,180.0)
	},

	]