import { useAnimations,} from "@react-three/drei";
import { useEffect,useRef } from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";


export default function CrispAnim() {
  //   const rotationInRadians = [
  //     THREE.MathUtils.degToRad(rotation[0]),
  //     THREE.MathUtils.degToRad(-rotation[2]),
  //     THREE.MathUtils.degToRad(rotation[0]),
  //   ];

  // const modelRef = useRef()
  const ref = useRef();

  const { crispAnim,steelRough } = GlbLoader();

  const { actions, names, mixer } = useAnimations(crispAnim.animations, ref);

 // Convert Euler angles to quaternion
const euler = new THREE.Euler(
    THREE.MathUtils.degToRad(0),  // Rotation around X-axis
    THREE.MathUtils.degToRad(-180), // Rotation around Y-axis
    THREE.MathUtils.degToRad(0),   // Rotation around Z-axis
    'ZYX'
  );
  
  const quaternion = new THREE.Quaternion().setFromEuler(euler);
  
  // Convert quaternion back to Euler angles
  const resultingEuler = new THREE.Euler().setFromQuaternion(quaternion);




  const action = actions[names[0]];











  useEffect(() => {

  
  

     const action = actions[names[0]];
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopRepeat);

    
      // Door is closed, play the animation to open
      action.reset();
      action.timeScale = 1;
      action.play();

  }, []);




  const [
    FlapsC_Map_1_AO_Shape,
    Chrisp_disorded,
    Crisp,
    FlapsC_Map_0_AO_Shape,
    Chrisp_orderd_tile_v1,
    SprayD_Map_0_AO_Shape,
    SprayD_Map_1_AO_Shape

  ] = useLoader(TextureLoader,[
    "../overallTextures/crispAnim/FlapsC_Map_1_AO_Shape.jpg",
    "../overallTextures/crispAnim/Chrisp_disorded_tile_v1.jpg",
    "../overallTextures/crispAnim/Crisp.png",
    "../overallTextures/crispAnim/FlapsC_Map_0_AO_Shape.jpg",
    "../overallTextures/crispAnim/Chrisp_orderd_tile_v1.png",
    "../overallTextures/crispAnim/SprayD_Map_0_AO_Shape.jpg",
    "../overallTextures/crispAnim/SprayD_Map_1_AO_Shape.jpg",


 

   
  ],)

    FlapsC_Map_1_AO_Shape.encoding = THREE.sRGBEncoding;
    FlapsC_Map_1_AO_Shape.flipY = false;

    Crisp.encoding = THREE.sRGBEncoding;
    Crisp.flipY = false;


    const crispMaterial = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
      map: Crisp,
        roughness: 1,
      
        transparent: true
      });

      const Band = new THREE.MeshStandardMaterial({
        color: 0x0099ff,

        roughness: 0.8,
      
      
      });

      const inoxFlapsC_Map_1_Material = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
      //  metalness: 1,
        roughness: 1,
        roughnessMap: steelRough,
        metalness: 0.9,
        aoMap: FlapsC_Map_1_AO_Shape
      });


      FlapsC_Map_0_AO_Shape.encoding = THREE.sRGBEncoding;
      FlapsC_Map_0_AO_Shape.flipY = false;


      SprayD_Map_0_AO_Shape.encoding = THREE.sRGBEncoding;
      SprayD_Map_0_AO_Shape.flipY = false;

      const SprayD_Map_0_AO_Shape_Material = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
      //  metalness: 1,
        roughness: 1,
        roughnessMap: steelRough,
        metalness: 0.9,
        aoMap: SprayD_Map_0_AO_Shape
      });

      SprayD_Map_1_AO_Shape.encoding = THREE.sRGBEncoding;
      SprayD_Map_1_AO_Shape.flipY = false;

      const SprayD_Map_1_AO_Shape_Material = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
      //  metalness: 1,
        roughness: 1,
        roughnessMap: steelRough,
        metalness: 0.9,
        aoMap: SprayD_Map_1_AO_Shape
      });


      const inoxFlapsC_Map_0_Material = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
      //  metalness: 1,
        roughness: 1,
        roughnessMap: steelRough,
        metalness: 0.9,
        aoMap: FlapsC_Map_0_AO_Shape
      });


      Chrisp_disorded.encoding = THREE.sRGBEncoding;
      Chrisp_disorded.flipY = false;

      Chrisp_disorded.repeat.set(3, 3);

      Chrisp_disorded.wrapS = THREE.RepeatWrapping;
      Chrisp_disorded.wrapT = THREE.RepeatWrapping;

      const chrisp_disorded_Material = new THREE.MeshStandardMaterial({
      
      map: Chrisp_disorded,
      //  metalness: 1,
        roughness: 0.8,
        // transparent: true
      });

      Chrisp_orderd_tile_v1.encoding = THREE.sRGBEncoding;
      Chrisp_orderd_tile_v1.flipY = false;

      Chrisp_orderd_tile_v1.repeat.set(1, 1);

      Chrisp_orderd_tile_v1.wrapS = THREE.RepeatWrapping;
      Chrisp_orderd_tile_v1.wrapT = THREE.RepeatWrapping;

      const Chrisp_orderd_tile_v1_Material = new THREE.MeshStandardMaterial({
      
      map: Chrisp_orderd_tile_v1,
      //  metalness: 1,
        roughness: 0.8,
        transparent: true
      });

      assignMaterialToObjects(crispAnim.scene, ["Material_FlapsC_Map_1_AO_inox"], inoxFlapsC_Map_1_Material);
      assignMaterialToObjects(crispAnim.scene, ["Chrisp_tile_Model"], chrisp_disorded_Material);
      assignMaterialToObjects(crispAnim.scene, ["Chrisp22"], crispMaterial);
      assignMaterialToObjects(crispAnim.scene, ["pCube41"], inoxFlapsC_Map_0_Material);
      assignMaterialToObjects(crispAnim.scene, ["BRep_1"], Band);
      assignMaterialToObjects(crispAnim.scene, ["Masse_IFC_geo","Masse_DuoCut_geo"], Chrisp_orderd_tile_v1_Material);
      assignMaterialToObjects(crispAnim.scene, ["Material_SprayD_Map_0_AO_inox"], SprayD_Map_0_AO_Shape_Material);
      assignMaterialToObjects(crispAnim.scene, ["Material_SprayD_Map_1_AO_xModel"], SprayD_Map_1_AO_Shape_Material);

  return (
    <>

      <primitive
       
        
        object={crispAnim.scene}
        ref={ref}
        dispose={null}
       rotation={euler}
       position={[-89,5.5,-8.8]}
      >
           
    
      </primitive>
    </>
  );
}
// useGLTF.preload("../models/EntryRoom/Pointer.glb");
function assignMaterialToObjects(scene, objectNames, material) {
    objectNames.forEach((objectName) => {
      const object = scene.getObjectByName(objectName);
      if (object) {
        object.material = material;
      }
    });
  }