import './style.css'
import ReactDOM from 'react-dom/client'
import Experience from './Experience.jsx'
import Header from './MainUI/Header'


const root = ReactDOM.createRoot(document.querySelector('#root'))
// const rootInterface = ReactDOM.createRoot(document.querySelector('#interface'))



// rootInterface.render(
//   <>
// <Header/>
//       </>
  
// )

root.render(
    <>
  <Experience/>
        </>
    
)

