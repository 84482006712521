import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import { stateOrbitLocation, statePlayerPositionS, statePlayerSwitch } from "../store";
import { OrbitControls } from "@react-three/drei";
import { useRef, useEffect, useState } from "react";
import { usePlayerPosition } from "./PlayerPositionContext";
// import { useState } from "react"
let diff = 0 


const CameraControls = ({}) => {

  const { camTarget } = usePlayerPosition();
  // const snapPlayerPostionS = useSnapshot(statePlayerPositionS)
  const snapOrbitCamera = useSnapshot(stateOrbitLocation);
  // const snapPlayerPositionS = useSnapshot(statePlayerPositionS);
  const cameraOrbitGoalPosition = snapOrbitCamera.CameraPos;
  const targetOrbitGoalPosition = snapOrbitCamera.TargetPos;
  const orbitClicked = snapOrbitCamera.orbitClicked;
  const shouldUpdate = snapOrbitCamera.shouldUpdate;
  const camera = useThree((state) => state.camera);
  const orbitRef = useRef();
  const initialCameraPosition = useRef(new THREE.Vector3());
  const orbitTargetPosition = useRef(new THREE.Vector3());
  const snapPlayerswitch = useSnapshot(statePlayerSwitch)
  const [orbitEnabled, setOrbitEnabled] = useState(true)
  
  
  useEffect(()=>{

    if(snapOrbitCamera.lerpRuns){
    setOrbitEnabled(false)
  }
  else{
    setOrbitEnabled(true)
  }
  },[snapOrbitCamera.lerpRuns])


  useEffect(() => {
    // Store the initial camera position when the component mounts
    if (orbitClicked ) {
   
      initialCameraPosition.current.copy(camera.position);
      orbitTargetPosition.current.copy(targetOrbitGoalPosition);
   orbitRef.current.target.copy(camTarget)
   stateOrbitLocation.lerpRuns = true
   statePlayerPositionS.playeractive = false;
    }
 
  }, [orbitClicked]);

  useFrame(({ scene }) => {

    if (orbitClicked && shouldUpdate ) {

      

      camera.position.lerp(cameraOrbitGoalPosition, 0.05);

      orbitRef.current.target.lerp(targetOrbitGoalPosition, 0.05);

      diff = camera.position
        .clone()
        .sub(cameraOrbitGoalPosition)
        .length();
      if (diff < 0.5) {
        stateOrbitLocation.lerpRuns = false
        stateOrbitLocation.shouldUpdate = false;
 
      }

    

    } else if (!orbitClicked && !shouldUpdate) {
 
      camera.position.lerp(initialCameraPosition.current, 0.05);
      stateOrbitLocation.lerpRuns = true
      orbitRef.current.target.lerp(
        camTarget,
        0.05
      );

     diff = camera.position
        .clone()
        .sub(initialCameraPosition.current)
        .length();
      if (diff < 0.5) {
        stateOrbitLocation.shouldUpdate = true;
        stateOrbitLocation.lerpRuns = false
        statePlayerPositionS.playeractive = true;
       
      
      }
    }
  });

  return (
    <>
     {!snapPlayerswitch.charSwitchClicked && <OrbitControls ref={orbitRef} target={orbitTargetPosition.current} enableRotate={orbitEnabled}/>}
    </>
  );
};

export default CameraControls;
