
import "./InfoWindow.css";

import { stateUiDisplay } from "../store";

import { useRef } from "react";
import { motion } from "framer-motion";


export default function InfoWindow({ matchingText }) {
  const ref = useRef();

  return (
    <>
      <motion.div
        className="modal"
        initial={{ opacity: 0, x: 1000 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 1000 }}
        transition={{ type: "spring", duration: 1.8, delay: 0.3 }}
      >
        <h1 className="modal__title">{matchingText.Titel}</h1>
        <div ref={ref} className="modal__text">
          

       
        {matchingText.Text}
        </div>
        <button className="nextButtonInfo" onClick={()=> stateUiDisplay.openInfoWindow = false }> close </button>
      </motion.div>
    </>
  );
}
