import "./Joystickb.css";
import { stateTouchCircle } from "../store";
import { stateDevice } from "../store";
import { useSnapshot } from "valtio";
import { useLayoutEffect } from "react";

import { Joystick } from "react-joystick-component";
import { useEffect } from "react";
import { useState } from "react";


export default function JoystickMovement() {

  const [joySize, setjoySize] = useState()
  const [joysizeThumb, setjoysizeThumb] = useState()

const snapIsMobile = useSnapshot(stateDevice)

  const handleMove = (e) => {
 
    // console.log(e);
   
    stateTouchCircle.x = e.x / 5,5;
    stateTouchCircle.y =- e.y ;
  };
  const handleStop = (e) => {
 
    // console.log(e);
    stateTouchCircle.joystickMove = false
    stateTouchCircle.x = 0;
    stateTouchCircle.y = 0;
  };

  const handleStart = (e) => {
 
    // console.log(e);
    stateTouchCircle.joystickMove = true

  };


  useEffect(()=>{
    if (snapIsMobile.isMobile && !snapIsMobile.isTablet)
    {
      setjoySize(100)
      setjoysizeThumb(40)

    }
    else{
      setjoySize(150)
      setjoysizeThumb(70)
    }
    
      },[snapIsMobile.isMobile])

 
  return (
    <div className="joystickLeft">
   <Joystick
   
        size={joySize}
        baseColor="red"
        stickColor="blue"
        stickSize={joysizeThumb}
        move={handleMove}
        stop={handleStop}
        start = {handleStart}
        baseImage="./UI/JoystickBackground.png"
        stickImage="./UI/JoystickThumb.png"
        // throttle = {100}
      />
    </div>
  );
}
