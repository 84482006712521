import "./ThemeMonitor.css";
import { Plane, Text } from "@react-three/drei";
import { Clone, Html } from "@react-three/drei";
import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import stateIntroMonitor from "./states/stateIntroMonitor";
import { stateInArea } from "../store";
import CheckInArea from "../Utils/CheckInArea.jsx";
import { useSnapshot } from "valtio";
import { useMemo } from "react";

const mPointsLength = stateIntroMonitor.length;

export default function IntroMonitor() {
  
  const [clones, setClones] = useState([]);

  const clonesData = useMemo(() => {
    // Calculate the rotation for each clone based on its index (x)
    return [...Array(mPointsLength).keys()].map((monitor, x) => {
      const {
        x: rotationX,
        y: rotationY,
        z: rotationZ,
      } = stateIntroMonitor[x].Rotation;
  
      const rotationInRadians = [
        THREE.MathUtils.degToRad(rotationX),
        THREE.MathUtils.degToRad(-rotationZ),
        THREE.MathUtils.degToRad(rotationY),
      ];
  
      return {
        key: x,
        position: [...stateIntroMonitor[x].Position],
        rotation: rotationInRadians,
        index: [...stateIntroMonitor[x].Id],
      };
    });
  }, [mPointsLength, stateIntroMonitor]);
  
  useEffect(() => {
    setClones(clonesData);
  }, []);

  return (
    <>
      {clones.map((clone) => (
        <IntroMonitorKon
          key={clone.key}
          scale={1}
          position={clone.position}
          rotation={clone.rotation}
          index={stateIntroMonitor[clone.key].Id}
        ></IntroMonitorKon>
      ))}
    </>
  );
}

function IntroMonitorKon({ position, rotation, index }) {
  const modelRef = useRef();
  const { introMonitor} = GlbLoader();
  const [iframeActive, setIframeActive] = useState(false);
  const snapPlayerInsideArea = useSnapshot(stateInArea);
  const playerInsideArea = snapPlayerInsideArea.inArea;
  const [areaActive, setAreaActive] = useState(false);

  const screenPositionStatic = new THREE.Vector3(-7.00777, 4.09774, 0);

// Convert Euler angles to quaternion
const euler = new THREE.Euler(
    THREE.MathUtils.degToRad(10),  // Rotation around X-axis
    THREE.MathUtils.degToRad(-90), // Rotation around Y-axis
    THREE.MathUtils.degToRad(-40),   // Rotation around Z-axis
    'ZYX'
  );
  
  const quaternion = new THREE.Quaternion().setFromEuler(euler);
  
  // Convert quaternion back to Euler angles
  const resultingEuler = new THREE.Euler().setFromQuaternion(quaternion);
  
  // Use the resultingEuler for setting rotation

  const titelFont = {
    font: "/Fonts/Roboto-Regular-webfont.woff",
    // font: "/Fonts/Roboto-Regular-webfont.woff",

    letterSpacing: -0.05,
    lineHeight: 1,
    "material-toneMapped": false,
    fontSize: 1,
    anchorX: "left",
    anchorY: "top",
    characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
  };





  const handlePlaneClick = () => {
    if (areaActive) {
      setIframeActive(true);
    }
  };

  useEffect(() => {
    // Call the callback function with the updated playerInsideArea value
    if (!playerInsideArea) {
      setIframeActive(false);
     
    }

  
  }, [playerInsideArea]);

  return (
    <>
      <Clone
        scale={7}
        position={position}
        // rotation={rotation}
        object={introMonitor.scene}
        ref={modelRef}
        dispose = {null}
        //   onPointerOver={(e) => setHover(true)}
        //   onPointerOut={(e) => setHover(false)}
        // onPointerDown= {onPointerDown}
      >


  
      </Clone>
      <CheckInArea
        squareA={100}
        squareB={100}
        position={position}
        index={index}
        setAreaActive={setAreaActive}
      />
    </>
  );
}
// useGLTF.preload("../models/EntryRoom/Pointer.glb");
