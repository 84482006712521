import EntryRoom from "./EntryRoom/EntryRoom.jsx";
import Player from "./Player/Player.jsx";
import CameraControls from "./Utils/CameraControls.jsx";
import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/rapier";

import "./Experience.css";
import Header from "./MainUI/Header";
import LoadingBar from "./MainUI/LoadingBar";
import ToolBar from "./MainUI/ToolBar";
import { useState, useRef, Suspense, useEffect } from "react";
import InfoWindowStory from "./MainUI/InfoWindowStory.jsx";
import Lights from "./Lights.jsx";
import { AnimatePresence } from "framer-motion";
import { useSnapshot } from "valtio";
import JoystickMovement from "./MainUI/Joystick.jsx";
import { Perf } from "r3f-perf";

import InfoStation from "./EntryRoom/InfoStation";
import EntryRoomCollider from "./EntryRoom/EntryRoomCollider.jsx";
import { PlayerPositionProvider } from "./Utils/PlayerPositionContext";
import ThemeMonitor from "./EntryRoom/ThemeMonitor";
import IframeWindow from "./MainUI/IFrameWindow.jsx";
import JoystickLook from "./MainUI/JoystickLook.jsx";
import GlbLoaderMDR from "./Utils/GlbLoaderMDR.jsx";
import {
  stateWallPush,
  statePlayerSwitch,
  stateTouchCircleLook,
  stateTouchCircle,
  stateIframeWindow,
  stateDevice,
  stateLoadingScreen,
  stateRoomDisplay,
  stateOrbitLocation,
  statePlayerPositionS
} from "./store.jsx";


import { Preload } from "@react-three/drei";
import {
  PerformanceMonitor,
  AdaptiveDpr,
  AdaptiveEvents,
  Bvh,
} from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import CameraControlsCharSwitch from "./Utils/CameraControslCharSwitch.jsx";
import round from "lodash/round";
import CharacterSwitchUi from "./MainUI/characterSwitchUi.jsx";
import { useDetectGPU } from "@react-three/drei";
import Sound from "./Utils/Sound.jsx";

function Loader() {
  const {} = GlbLoaderMDR();
}

export default function Experience() {
  // performance: {
  //   current: 1,
  //   min: 0.1,
  //   max: 1,
  //   debounce: 200,
  //   regress: () => void,
  // },
  const GPUTier = useDetectGPU();
  const [start, setStart] = useState(false);
  const [gravity, setGravity] = useState(0);
  const snapLaodingscreen = useSnapshot(stateLoadingScreen);
  const snapIframeWindow = useSnapshot(stateIframeWindow);
  const snapWallPushIndex = useSnapshot(stateWallPush);
  const snapPlayerSwitch = useSnapshot(statePlayerSwitch);
  const snapTouchCircleLook = useSnapshot(stateTouchCircleLook);
  const snapTouchCircle = useSnapshot(stateTouchCircle);
  const snapOrbitLocation = useSnapshot(stateOrbitLocation);
  const snapPlayerPositionS = useSnapshot(statePlayerPositionS);
  // const snapStateInArea = useSnapshot(stateInArea)
  const canvasRef = useRef();
  const [enableMPerfMon, setEnableMPerfMon] = useState(false);
  const [aniRunEndDone, setAniRunEndDone] = useState(false);
  // canvasRef.current.pointerEvents = 'null';

  const iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === "MacIntel" &&
      navigator.maxTouchPoints > 1); /* iPad OS 13 */

  stateDevice.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  stateDevice.isTablet = /Tablet/i.test(navigator.userAgent);


  useEffect(() => {
    stateIframeWindow.renderCanvas = true;
  }, [snapLaodingscreen.loadingScreenAnimate]);



  if (iPad) {
    stateDevice.isMobile = true;
    stateDevice.isTablet = true;
  }


  const [dpr, setDpr] = useState([1,2]);
  const [light, setLight] = useState(true);

  const FOV = 60;
  console.log(!snapLaodingscreen.loadingScreenAnimate);

  useEffect(() => {
    // Set DPR based on GPU tier
    if (GPUTier.type === "FALLBACK") {
      // setDpr(1)
       setEnableMPerfMon(true);
    }
    else  if (GPUTier.tier === 1 && GPUTier.type != "FALLBACK") {
      setDpr(1); // Set DPR for tier 1
    } else if (GPUTier.tier === 2) {
      setDpr(1.5); // Set DPR for tier 2
    } else if (GPUTier.tier === 3) {
      console.log(dpr);
      setDpr(2); // Set DPR for tier 3
    } else if (GPUTier.tier === 0) {
      setLight(false);
      setDpr(1); // Set DPR for tier 3
    }
 
  }, [GPUTier.tier]);

  function handleRoomClick(){
   
    stateRoomDisplay.roomDisplay= !stateRoomDisplay.roomDisplay;
    console.log(stateRoomDisplay.roomDisplay)
  }

  return (
    <>
      <PlayerPositionProvider>
        <Canvas
          gl={{ toneMappingExposure: 0.9 }}
          // performance={
          //   {current:2, min: 0.1, max:2, }
          // }
          // performance={{ min: 0.5 }}
          // invalidateFrameloop = {active}
          logarithmicDepthBuffer={true}
          ref={canvasRef}
          frameloop={snapIframeWindow.renderCanvas ? "always" : "never"}
          // noEvents = {true}
          shadows={false}
          // onPointerMove={null}
          //  colorManagement
          // pixelRatio = {window.devicePixelRatio}
          // gl2 = {true }
          // gl={{ antialias: true }}
          // // dpr={Math.max(window.devicePixelRatio, 1.5)}
          dpr={dpr}
          // pixelRatio={1}
          // dpr={[1, dpr]}
          camera={{ fov: FOV, position: [0, 20, -180], near: 10, far: 1500 }}
        >
         
          {/* <color attach="background" args={[bgColor]} /> */}
          {/* {aniRunEndDone && <PerformanceMonitor onIncline={() => setDpr(2)} onDecline={() => {setLight(false)}  } flipflops={4}></PerformanceMonitor>} */}
          {enableMPerfMon &&  aniRunEndDone &&
            <PerformanceMonitor
              onIncline={() => setDpr(2)}
              onDecline={() => setDpr(1)}
              flipflops={3}
            ></PerformanceMonitor>
          }
          <Lights />
         
          {!snapLaodingscreen.loadingScreenAnimate && <CameraControls />}
          <CameraControlsCharSwitch></CameraControlsCharSwitch>
          {light && (
            <directionalLight
              intensity={0.75}
              position={[30, 40, 60]}
              shadow={false}
            />
          )}

          <ambientLight intensity={0.5} shadow={false} />

          {/* <directionalLight ></directionalLight>
          <ambientLight castShadow={false}></ambientLight> 
  {!snapLaodingscreen.loadingScreenAnimate &&   <Environment 
    preset = {"city"}
    
    background = {false}
    intensity={1}
    resolution={64}
    color="black" 


    // files= "./overallTextures/industrial_room.hdr"
   
   />} */}

          {/*    */}
          <Suspense>
         
            <Physics
              gravity={[0, gravity, 0]}
              interpolation={false}
              timeStep={1 / 30}
              colliders={false}
            >
              {!snapLaodingscreen.loadingScreenAnimate && <EntryRoomCollider />}

              {aniRunEndDone && start && <Player />}
            </Physics>
           
            {!snapLaodingscreen.loadingScreenAnimate && <ThemeMonitor />}
            {!snapLaodingscreen.loadingScreenAnimate && <EntryRoom ></EntryRoom>}

            {snapLaodingscreen.lastInfoWindowClick && <InfoStation />}
            
          </Suspense>

          {/* <Perf 
// overClock= {true}
position ="top-left"></Perf> */}
 <Preload all />
          {/* <AdaptiveDpr pixelated/> */}
          <AdaptiveEvents /> 
          {/* <AdaptivePixelRatio />  */}
        </Canvas>
      </PlayerPositionProvider>
      {snapLaodingscreen.lastInfoWindowClick &&
        stateDevice.isMobile &&
        !snapPlayerSwitch.charSwitchClicked &&
        !snapOrbitLocation.orbitClicked && <JoystickMovement />}
      {snapLaodingscreen.lastInfoWindowClick &&
        snapTouchCircleLook.JoystickLookVisible &&
        !snapTouchCircle.joystickMove &&
        !snapPlayerSwitch.charSwitchClicked &&
        !snapOrbitLocation.orbitClicked &&
        !snapPlayerPositionS.fpPlayer && <JoystickLook />}

      {snapIframeWindow.showIframe && (
        <IframeWindow
          src={snapIframeWindow.src} // Replace with your desired URL
          // onClose={stateIframeWindow.showIframe = false}
        />
      )}
      {/* <IframeWindowScetchFab></IframeWindowScetchFab> */}

      <AnimatePresence
        key={"LoadingBar"}
        onExitComplete={() => setAniRunEndDone(true)}
      >
        {!start && (
          <LoadingBar
            started={start}
            onStarted={() => setStart(true) & setGravity(-1000)}
          />
        )}
      </AnimatePresence>
      {snapLaodingscreen.lastInfoWindowClick && !snapPlayerSwitch.characterUiOn && <ToolBar />}
      <AnimatePresence key={"Toolbar"}>
        {snapLaodingscreen.lastInfoWindowClick && <Header />}
        {snapPlayerSwitch.characterUiOn && (
          <CharacterSwitchUi></CharacterSwitchUi>
        )}
      </AnimatePresence>
      <AnimatePresence key={"Infowindow"}>
        {start && <InfoWindowStory />}
      </AnimatePresence>
      {start &&<div className="RoomOnOff"
      onPointerDown={handleRoomClick}>
        <p>
         RoomOn/OFF
        </p>
      </div>}

      {/* <div className="interfaceGPU">
        <p>
          GPU Tier: {GPUTier.tier},DPR: {dpr},GPU Type: {GPUTier.type}
        </p>
      </div> */}
      {/* <Sound></Sound> */}
    </>
  );
}
