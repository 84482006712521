import { useState,} from "react";
import "./InfoWindowStory.css";
import { motion, AnimatePresence } from "framer-motion";
import { stateLoadingScreen } from "../store";

const parchmentVariants = {
  hidden: {
    opacity: 1,
    scale: 0,
    "--round-corner-top-right": "500px 500px",
    "--round-corner-top-left": "500px 500px",
    "--round-corner-bottom-left": "500px 500px",
    "--round-corner-bottom-right": "500px 500px",
    transition: { type: "linear", duration: 0.4, delay: 0.5 }
  },
  visible: {
    opacity: 1,
    scale: 1,
    "--round-corner-top-right": "15px 15px",
    "--round-corner-top-left": "15px 15px",
    "--round-corner-bottom-left": "15px 15px",
    "--round-corner-bottom-right": "15px 15px",
    transition: { type: "spring", duration: 3, delay: 3 }
  },
};



const bodyVariants = {
  hidden: {
    "--background-color": "rgba(0, 0, 0, 0)",
    transition: { type: "spring", duration: 2, delay: 3 },
  },
  visible: {
    "--background-color": "rgba(0, 0, 0, 0.75)",
    transition: { type: "spring", duration: 2, delay: 1 },
  },
};

const instructVariants = {
  hidden: {
   opacity: 0 ,
  },
  visible: {
    opacity: 1, 
  },
};
export default function InfoWindowStory({ titel, text }) {
  const [showStoryWindow, setShowStoryWindow] = useState(true);
  const [showNavyWindow, setShowNavyWindow] = useState(false);

  const handlePointerDown = () => {
    setShowStoryWindow(false);
    setShowNavyWindow(true);
  };

  const handlePointerDownNav = () => {
    setShowNavyWindow(false);
    stateLoadingScreen.lastInfoWindowClick = true
  };

  return (
    <>
      <AnimatePresence>
        {showStoryWindow && 
          <InfoWindowStoryKon onPointerDown={handlePointerDown} setShowNavyWindow = {setShowNavyWindow} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {showNavyWindow  && <InfoWindowNaviKon onPointerDown={handlePointerDownNav} />}
      </AnimatePresence>
 
    </>
  );
}

function InfoWindowStoryKon({ onPointerDown,setShowNavyWindow }) {
  const [titel, setTitel] = useState("WILLKOMMEN IN DER ONLINE SOLUTIONSNET");
  const [text, setText] = useState(
    "Laufe durch die MagicLens so wie du es dir immer erträumt hast"
  );

  const handleMouseDown = () => {
    onPointerDown();

  };

  return (
    <>
  
      <motion.div
      className="background"
        onPointerDown={handleMouseDown}
        variants={bodyVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
  

  
        <motion.div
          className="parchment"
          variants={parchmentVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          // transition={{ type: "spring", duration: 2, delay: 3 }}
        >
          <h1>{titel}</h1>
          <p>{text} </p>
          {/* <div className="imageDiv"><img src="./UI/StoryButton.png"></img></div> */}
          <button className="nextButton"> next </button>
        </motion.div>


        <motion.div className="orangeDivLeft"
    
    initial={{ opacity: 1, x: 0 }}
        animate={{ opacity: 1, x: -600 }}
        exit={{ opacity: 0, x: -1500, duration: 0.5, delay: 0.5  }}
        transition={{ type: "spring", duration: 3.5, delay: 2 }}
        ></motion.div>

<motion.div className="orangeDivRight"
    
    initial={{ opacity: 1, x: 0 }}
        animate={{ opacity: 1, x: 600 }}
        exit={{ opacity: 0, x: 1500, duration: 0.5, delay: 0 }}
        transition={{ type: "spring", duration: 3.5, delay: 2 }}
        ></motion.div>
      </motion.div>
     
      {/* <div className="inkTitle">"Herzlicsssssssssssssssssssssssssch"</div> */}
      {/* <svg>
  <filter id="wavy2">
    <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed="1" />
    <feDisplacementMap in="SourceGraphic" scale="10" />
  </filter>
</svg> */}

    </>
  );
}

function InfoWindowNaviKon({onPointerDown}) {
  const [titel, setTitel] = useState("Starte deine Experience");
  const [text, setText] = useState(
    "Ziehe die Maus mit gedrückter linker Maustaste über den Bildschirm. Im oberen Bereich bewegst du dich vorwärts, in der unteren Hälfte kannst du dich drehen. "
  );

  const handleMouseDown = () => {
    // statedynamicLight.videoLight = false
      onPointerDown();
  }

  return (
    <>
      <motion.div
      className="background"
    
        variants={bodyVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
  



        <motion.div
          className="parchment"
          initial={{ x: 1500 }}
        animate={{  x: "60%"  }}
        exit={{  x: 1500, type: "spring", duration: 1, delay:  0 }}
        transition={{ type: "spring", duration: 2, delay: 
        2 }}
        >
          <h1>{titel}</h1>
          <p>{text} </p>
          {/* <div className="imageDiv"><img src="./UI/StoryButton.png"></img></div> */}
          <button className="nextButton"   onPointerDown={handleMouseDown}> next </button>
        </motion.div>
        <motion.div className="orangeDivLeftDown"
    
    initial={{ opacity: 1, x: -1300 }}
        animate={{ opacity: 1, x: "100%"  }}
        exit={{opacity: 0,  x: -1300,  duration: 1, delay:  0 }}
        transition={{ type: "spring", duration: 3.5, delay: 
        2 }}
        ></motion.div>
        <motion.div
         initial={{ opacity: 0, x: -1000 }}
        animate={{ opacity: 1, x: "10%"}}
        exit={{ opacity: 0,  x: -1000, type: "spring", duration: 1, delay:  0 }}
        transition={{ type: "spring", duration: 3.5, delay: 
        2 }}>
    <img
            className="helpRun"
            src="./UI/helpRun.svg"
            alt="Orbit Controls"
          />
       

<img
            className="helpTurn"
            src="./UI/helpTurn.svg"
            alt="Orbit Controls"
          />
          </motion.div>

          <motion.div className= "line"
           initial={{ opacity: 0, x: -3500 }}
        animate={{ opacity: 1, x: -1100 }}
        exit={{ opacity: 0, x: -3500, type: "spring", duration: 1, delay:  0 }}
        transition={{ type: "spring", duration: 3.5, delay: 
        2 }}></motion.div>
      </motion.div>

      {/* <div className="inkTitle">"Herzlicsssssssssssssssssssssssssch"</div> */}
      {/* <svg>
    <filter id="wavy2">
      <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed="1" />
      <feDisplacementMap in="SourceGraphic" scale="10" />
    </filter>
  </svg> */}
    </>
  );
}



