
import "./LoadingBar.css";
import { motion, } from "framer-motion";
import { useEffect, useState } from "react";
import { stateLoadingScreen } from "../store";
import { useSnapshot } from "valtio";

// import { stateIndex } from "../store";

const bodyVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
 
  };

const regionIconVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 4,
  },
};

const LogoVariants = {
  hidden: {
    opacity: 0,
    x: -1000,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 0,
  },
};

const ArrowVariants = {
    hidden: {
      x: 1000,
    },
    visible: {
      x: 0,
    },
    exit: {
      x: 0,
    },
  };

export default function LoadingBar({ started, onStarted }) {
  return (
    <>
      <LoadingBarKon started={started} onStarted={onStarted} />
    </>
  );
}

function LoadingBarKon({ started, onStarted }) {
 
  const snapLoadingPercent = useSnapshot(stateLoadingScreen)
  const [aniRun, setAniRun] = useState(true);
  // const [aniRunEndDone, setAniRunEndDone] = useState(false);

  useEffect(() => {
    
   
    stateLoadingScreen.loadingScreenAnimate = aniRun

  }, [aniRun]);

  // useEffect(() => {
  //   console.log(aniRunEndDone)
    
  //   stateLoadingScreen.loadingScreenAnimate = aniRun

  // }, [aniRunEndDone]);

  return (
    <>

      <motion.div className="startBackground" 
      variants={ bodyVariants}
          
          animate="visible"
          exit="hidden"
          transition={{ type: "linear", duration: 2, delay: 1 }}
      >
            <motion.div
          className= "textPercent">
          {snapLoadingPercent.basePercent} % loaded
          </motion.div>
        <motion.div
          className="LogoContainer"
          variants={LogoVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ type: "spring", duration: 2, delay: 0.5 }}
          onAnimationComplete={() => setAniRun(false)} 
          // onExitComplete={() => setAniRunEndDone(true)} 
        >
          {/* <div className="Logo">BÜHLER</div>
          <div className="LogoSentence">SolutionNET</div> */}
          {/* <div className="LogoSentence2">Connected History</div> */}
        </motion.div>
        <motion.button
        
            className="startButton"
            disabled={snapLoadingPercent.basePercent < 100}
            onClick={onStarted}
          >
            eintreten{" "}
          </motion.button>
        <motion.div className="image-container">
          {/* <img src="UI/Dot_Arrow.svg" alt="Museum Icon"/> */}

     
          <motion.div
            className="loading-bar"
            style={{
              width: `${snapLoadingPercent.basePercent}%`,
            }}
          />
        
        </motion.div>
        {/* <motion.img
          className="dotArrow"
          src="UI/Dot_Arrow_white.svg"
          alt="Museum Icon"

          variants={ArrowVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ type: "spring", duration: 2, delay: 0.5 }}
          
        /> */}
        <motion.img
          className="MuseumIcon"
          src="UI/IconMsueum.svg"
          alt="Museum Icon"
          variants={regionIconVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ type: "spring", duration: 2, delay: 0.5 }}
        />
      </motion.div>
    </>
  );
}
