
import "./IframeWindow.css"
import { stateIframeWindow } from '../store';

const IframeWindow = ({ src, onClose }) => {
  

  const handleClose = () => {
    stateIframeWindow.showIframe = false
    stateIframeWindow.renderCanvas = true
    console.log()
  };

  return (
    <>
    
        <div className="iframeContainer">
          <div className="iframeContent">
            <button className="closeButton" onPointerDown={handleClose}>
              
            </button>
            <iframe  src={src} title="Iframe Window" className= "iframeWindow" />
          </div>
        </div>
  
    </>
  );
};

export default IframeWindow;