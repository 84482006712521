import { useAnimations, Shadow, Outlines } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef, useEffect, useMemo, useState } from "react";
import { Euler } from "three";
import GlbLoader from "../Utils/GlbLoader.jsx";
import * as THREE from "three";
import useMouseInput from "../hooks/useMouseInput.jsx";
import { useSnapshot } from "valtio";
import {
  statePlayerPositionS,
  statePlayerSwitch,
  stateDevice,
  stateTouchCircle,
  stateTouchCircleLook,
  stateOrbitLocation,
} from "../store.jsx";
import { RigidBody, CapsuleCollider, vec3, quat } from "@react-three/rapier";
import { usePlayerPosition } from "../Utils/PlayerPositionContext";


// const quat = useMemo(() => new Quaternion(), [])
const currentPosition = new THREE.Vector3(0, 20, -180);
const currentRigidBodyPosition = new THREE.Vector3(0, 0, -150);
const currentLookat = new THREE.Vector3();
const timeDilation = 0.28; //50
let rotationAngle = 0;
const rotationSpeed = 5;
const threshold = 0; // Adjust the threshold value as desired
// const playerRun = false;
let rigidBodyPlayerVec = vec3();

let xPos, zPos;
let xPosLook, zPosLook;
// let cameraSavedPosition

const backwardThreshold = -0.1;

const bottomThreshold = -0.1; // Adjust the threshold as needed
const topThreshold = 0; // Adjust the threshold as needed
let idealOffset = new THREE.Vector3(2, 25, -35);
let idealLookat = new THREE.Vector3(0, 20, 0);

let impulse = new THREE.Vector3();
let speedFactor;
const quaternionRotation = new THREE.Quaternion();
const velocity = new THREE.Vector3();
let forwardDirection = new THREE.Quaternion();
let x = 0;
let y = 0;
let z = 0;
const cameraOffset = new THREE.Vector3();
const rigidBodyQuat = new THREE.Quaternion();
let horizontalAngle = 0;
let verticalAngle = 0;
const rigidBodyVec = vec3();
let adjustedZPos;
let verticalAngleMin = 0;
let verticalAngleMax = 0;
let rotatedCameraOffset = new THREE.Quaternion();
let elapsedTime = 0;
let t = 0.03;
let speed = 0
let action

export default function Player() {

  const [playerWomen, setPlayerWomen] = useState(false);
  return (
    <>
      {playerWomen ? (
        <PlayerWoman setPlayerWomen={setPlayerWomen}></PlayerWoman>
      ) : (
        <PlayerMale setPlayerWomen={setPlayerWomen}></PlayerMale>
      )}
    </>
  );
}

function PlayerMale({ setPlayerWomen }) {
  const { playerModel } = GlbLoader();

  const { actions, name } = useAnimations(
    playerModel.animations,
    playerModel.scene
  );

  return (
    <PlayerKon
      model={playerModel.scene}
      actions={actions}
      name={name}
      setPlayerWomen={setPlayerWomen}
    ></PlayerKon>
  );
}

function PlayerWoman({ setPlayerWomen }) {
  const { playerModelWoman } = GlbLoader();

  const { actions, name } = useAnimations(
    playerModelWoman.animations,
    playerModelWoman.scene
  );

  return (
    <PlayerKon
      model={playerModelWoman.scene}
      actions={actions}
      name={name}
      setPlayerWomen={setPlayerWomen}
    ></PlayerKon>
  );
}

function PlayerKon({ actions, model, name, setPlayerWomen }) {
  
  const euler = useMemo(() => new Euler(), []);
  // const speed = { value: 150, min: 100, max: 1000, step: 10 };

  const snapisMobile = useSnapshot(stateDevice);
  const snapTouchCircle = useSnapshot(stateTouchCircle);
  const snapTouchCircleLook = useSnapshot(stateTouchCircleLook);
  const snapSwitchPlayer = useSnapshot(statePlayerSwitch);
  const snapOrbitLocation = useSnapshot(stateOrbitLocation);
  const snapPlayerPosition = useSnapshot(statePlayerPositionS);

  const playerRef = useRef();
  const playerBodyRef = useRef();

  const { setPlayerPosition, setCamTarget,setPlayerQuat } = usePlayerPosition();

  useEffect(() => {
    playerBodyRef.current.setRotation(quaternionRotation);
    currentRigidBodyPosition.copy(rigidBodyPlayerVec);
    if (snapSwitchPlayer.playerWoman) {
      setPlayerWomen(true);
    } else {
      setPlayerWomen(false);
    }
    let action = "idle";
    const nextActionToPlay = actions[action];
    nextActionToPlay?.reset().play();
  }, [snapSwitchPlayer.playerWoman]);

  // const regress = useThree((state) => state.performance.regress)

  let newCameraPosition = new THREE.Vector3();


  actions.name = name;


  const currentAction = useRef("");

  // const canvas = useThree((state)=> state.controls)
  const camera = useThree((state) => state.camera);
  const stateTemp = { startInteract: true };

  let { mouse } = useMouseInput(stateTemp);

  const [cameraSavedTarget, setCameraSavedTarget] = useState(
    new THREE.Vector3()
  );
  const [cameraStill, setCameraStill] = useState(new THREE.Vector3());
  const [distanceToTarget, setDistanceToTarget] = useState();

  // Access the mouse coordinates from the hook

  useEffect(() => {
    if (snapTouchCircleLook.OrbitOn) {
      camera.position.copy(cameraStill);
      currentPosition.copy(CalculateIdealOffset());
      const currentLookAt = CalculateIdealLookat();
      const distanceToTarget = currentPosition.distanceTo(currentLookat);
      setCameraSavedTarget(currentLookAt);
      setDistanceToTarget(distanceToTarget);
    }
  }, [snapTouchCircleLook.OrbitOn]);

  useEffect(() => {
    if (snapPlayerPosition.fpPlayer) {
      // idealOffset.set(0, 20, 0)
      t = 0.1;
    } else {
      // idealOffset.set(2, 25, -35)
      t = 0.03;
    }
  }, [snapPlayerPosition.fpPlayer]);

  useEffect(()=>{

if (snapOrbitLocation.lerpRuns){
  action = "idle";

  setAction(action);
  }
  },[snapOrbitLocation.lerpRuns])

  // Character Walk animations
  const animate = (elapsedTime, zPos) => {

    action = "idle";

   speed = Math.abs(zPos);

    if (speed === 0) action = "idle";
    else if (speed < 0.7) action = "walk";
    else action = "run";

    setAction(action);

    // Synchronize the animation speed with the frame rate
    if (actions[action]) {
      actions[action].setEffectiveTimeScale(1);
      actions[action].setDuration(actions[action].getClip().duration);
      actions[action].time = elapsedTime % actions[action].getClip().duration;
    }
  };
  //Character Action blending
  const setAction = (action) => {
    if (currentAction.current != action) {
      const nextActionToPlay = actions[action];
      const current = actions[currentAction.current];
      current?.fadeOut(0.2);
      nextActionToPlay?.reset().fadeIn(0.2).play();
      currentAction.current = action;
    }
  };

  //Calculate FollowCamera
  const CalculateIdealOffset = () => {
    // console.log(snapPlayerPosition.fpPlayer)
    if (snapPlayerPosition.fpPlayer) {
      idealOffset.set(0, 20, 0);
    } else {
      idealOffset.set(2, 25, -35);
    }

    rigidBodyVec.copy(playerBodyRef.current.translation());
    rigidBodyQuat.copy(playerBodyRef.current.rotation());
    idealOffset.applyQuaternion(rigidBodyQuat);
    idealOffset.add(rigidBodyVec);

    return idealOffset;
  };

  const CalculateIdealLookat = () => {
    if (snapPlayerPosition.fpPlayer) {
      idealLookat.set(0, 20, 10);
    } else {
      idealLookat.set(0, 20, 0);
    }

    rigidBodyVec.copy(playerBodyRef.current.translation());
    rigidBodyQuat.copy(playerBodyRef.current.rotation());
    idealLookat.applyQuaternion(rigidBodyQuat);
    idealLookat.add(rigidBodyVec);

    return idealLookat;
  };

  useEffect(() => {
    setPlayerPosition(rigidBodyPlayerVec);
    setCamTarget(currentLookat);
    setPlayerQuat(forwardDirection)
  }, [rigidBodyPlayerVec, currentLookat]);

  useFrame((state, deltaTime) => {
    // regress()
    //Player Movement
    if (statePlayerPositionS.playeractive && !snapOrbitLocation.lerpRuns) {
      elapsedTime = state.clock.getElapsedTime();
      // state.performance.regress()
      xPosLook = snapTouchCircleLook.x;
      zPosLook = snapTouchCircleLook.y;

      // Set the camera's lookAt to the new look-at position
      if (!snapTouchCircleLook.OrbitOn) {
        if (!snapisMobile.isMobile && !snapTouchCircleLook.OrbitOn) {
          xPos = mouse.current.x;
          zPos = mouse.current.y;
        } else if (snapisMobile.isMobile) {
          xPos = snapTouchCircle.x;
          zPos = snapTouchCircle.y;
        }

        // const linvelY = playerBodyRef.current.linvel().y;

        if (zPos > backwardThreshold) {
          zPos = 0; // Set zPos to 0 to prevent backward movement
        }

        impulse.set(xPos, 0, zPos);

        // Adjust the speed factor based on zPos

        adjustedZPos = zPos;
        if (zPos < bottomThreshold) {
          adjustedZPos = bottomThreshold;
        } else if (zPos > topThreshold) {
          adjustedZPos = topThreshold;
        }

        // Calculate the speed factor based on the adjusted zPos
        speedFactor = 150 - adjustedZPos * deltaTime; // Adjust the factor as needed

        impulse.multiplyScalar(-speedFactor * timeDilation);

        if (xPos < -threshold) {
          rotationAngle += -xPos * rotationSpeed * deltaTime;

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        } else if (xPos > threshold) {
          rotationAngle += -(xPos * rotationSpeed * deltaTime);

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        }

        forwardDirection.copy(playerBodyRef.current.rotation());

        euler.y = rotationAngle;
        euler.order = "YZX";
        forwardDirection.setFromEuler(euler);
        impulse.applyQuaternion(forwardDirection);
        velocity.set(impulse.x, impulse.y, impulse.z);

        playerBodyRef.current.setLinvel(velocity);

        // const playerPosition = new THREE.Vector3(-zPos, 0, -xPos);

        animate(elapsedTime, zPos);
        // move(elapsedTime, playerPosition);
      }
      // Update the camera's look-at position based on xPosLook and zPosLook

      if (snapTouchCircleLook.OrbitOn) {
        if (xPosLook < -threshold) {
          rotationAngle += -xPosLook * 2 * deltaTime;

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        } else if (xPosLook > threshold) {
          rotationAngle += -(xPosLook * 2 * deltaTime);

          quaternionRotation.setFromEuler(new THREE.Euler(0, rotationAngle, 0));
          playerBodyRef.current.setRotation(quaternionRotation);
        }

        forwardDirection.copy(playerBodyRef.current.rotation());

        euler.y = rotationAngle;
        euler.order = "YZX";
        forwardDirection.setFromEuler(euler);
        impulse.applyQuaternion(forwardDirection);
        velocity.set(impulse.x, impulse.y, impulse.z);
        // const dampingFactor = 0.1;
        // zPosLook *= dampingFactor;
        // horizontalAngle = xPosLook * Math.PI

        verticalAngle = (zPosLook * Math.PI) / 3;

        verticalAngleMin = -1.7;
        verticalAngleMax = -0.24;
        verticalAngle = Math.max(
          verticalAngleMin,
          Math.min(verticalAngleMax, verticalAngle)
        );

        console.log(verticalAngle);
        // Distance from target (character)

        x = distanceToTarget * Math.sin(horizontalAngle) * 7;
        y = distanceToTarget * Math.cos(verticalAngle);
        z = distanceToTarget * Math.sin(verticalAngle);

        // Calculate the camera offset vector based on angles and radius
        cameraOffset.set(x, y, z);

        rigidBodyQuat.copy(playerBodyRef.current.rotation());
        // const rigidBodyQuat = quat(playerBodyRef.current.rotation());
        rigidBodyVec.copy(playerBodyRef.current.translation());
        // Apply the character's rotation to the camera offset

        rotatedCameraOffset = cameraOffset
          .clone()
          .applyQuaternion(rigidBodyQuat);

        // Calculate the new camera position as the sum of character position and rotated offset
        newCameraPosition.copy(rigidBodyVec.clone().add(rotatedCameraOffset));
        currentPosition.lerp(newCameraPosition, 0.1);
        currentLookat.lerp(cameraSavedTarget, 0.1);

        camera.position.copy(currentPosition);
        camera.lookAt(currentLookat);
        setCameraStill(newCameraPosition);
      } else if (
        !snapTouchCircleLook.OrbitOn &&
        snapTouchCircleLook.JoystickLookOn
      ) {
        // currentPosition.copy(camera.position)
        camera.position.copy(cameraStill);
        camera.lookAt(currentLookat);
        currentPosition.copy(cameraStill);
      }

      if (!snapTouchCircleLook.JoystickLookOn && !snapTouchCircleLook.OrbitOn) {
        // In follow mode, update the camera position and look-at as before
        const idealOffset = CalculateIdealOffset();
        const idealLookat = CalculateIdealLookat();

        currentPosition.lerp(idealOffset, t);
        currentLookat.lerp(idealLookat, t);
        camera.position.copy(currentPosition);
        camera.lookAt(currentLookat);
      }
    }
    // const rigidBodyPlayerVec = vec3(playerBodyRef.current.translation());
    rigidBodyPlayerVec.copy(playerBodyRef.current.translation());
    // statePlayerPositionS.playerCameraTargetPosition.copy(currentLookat);
    // playerposition.copy(rigidBodyPlayerVec);
    // statePlayerPositionS.playerPositionS.copy(rigidBodyPlayerVec);
    // const isRunning = speed >= 0.1;

    if (!snapTouchCircleLook.OrbitOn && xPos != 0) {
      if ((stateTouchCircleLook.JoystickLookOn = true)) {
        stateTouchCircleLook.JoystickLookOn = false;
      }
    }
  });

  return (
    <RigidBody
      lockRotations={true}
      ref={playerBodyRef}
      colliders={false}
      position={currentRigidBodyPosition}
      // rotation={[0,-1.5,0]}
      restitution={0.2}
      friction={0} // was one
      gravityScale={true}
      type={"dynamic"}
      dispose={null}
    >
  
      <primitive
        ref={playerRef}
        object={model}
        scale={12}
        visible={snapPlayerPosition.fpPlayer ? false : true}
        dispose={null}
      >
      
      </primitive>
   
      <CapsuleCollider args={[7, 3]} position={[0, 10, 0]} />
      <Shadow position={[0, 0.1, 0]} scale={12} opacity={0.2} dispose={null} />
    </RigidBody>
  );
}
