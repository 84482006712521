import { useRef} from "react";

import GlbLoader from "../Utils/GlbLoader";

import { stateWallPush } from "../store";
import { useState } from "react";
import FocusPunkt from "./FocusPunkt";
import { useSnapshot } from "valtio";
import IntroMonitor from "./IntroMonitor";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import CrispAnim from "./CrispAnim";
import { Grid } from "@react-three/drei";
import { stateRoomDisplay } from "../store";

export default function EntryRoom() {
  // const themeComponents = useMemo(
  //   () => [
  //     MuseenDerRegion,
  //     Holzarbeit,
  //     Klima,
  //     Salzabbau,
  //     Handelswege,
  //     Menschen,
  //   ],
  //   []
  // );
  const { ...gridConfig } = {
   
    cellSize: 2,
    cellThickness: 1.8,
    cellColor: '#6f6f6f',
    sectionSize: 24,
    sectionThickness: 2,
    // sectionColor: '#9d4b4b',
    sectionColor: 'white',
    // fadeDistance: 25,
    fadeStrength: 1.0,
    followCamera: false,
    infiniteGrid: true
  }


  const snapRoomDisplay = useSnapshot(stateRoomDisplay);
  const entryRoomRef = useRef();
  const productsRef = useRef();
  const [DoorCloseAnimationEnd,setDoorCloseAnimationEnd] = useState(false)
  const { entryRoomModel,MainMenue, products} = GlbLoader();

  const menuePositionStatic = new THREE.Vector3((100,-600, 80))

  // })
  useFrame(() => {
    // Rotate the clone in the animation loop
    productsRef.current.rotation.y += 0.0005; 
// Adjust the rotation speed as needed
  });

  return (
    <>
    <FocusPunkt></FocusPunkt>
    <CrispAnim></CrispAnim>
    <IntroMonitor></IntroMonitor>
    {snapRoomDisplay.roomDisplay && <primitive
        ref={entryRoomRef}
        object={entryRoomModel.scene}
        scale={1.5}
        dispose={null}
      />}
     <primitive
        ref={entryRoomRef}
        object={MainMenue.scene}
        scale={2}
        dispose={null}
        position={[0,10,-100]}
      />
         <primitive
        ref={productsRef}
        object={products.scene}
        scale={1.5}
        dispose={null}
        
      />
       {!snapRoomDisplay.roomDisplay && <Grid 
  position={[0, 0.4, 0]}
 args={[10,10]}
    { ...gridConfig}
    dispose = {null}
    ></Grid>}
      {/* <RoomPush 
        setDoorCloseAnimationEnd  ={setDoorCloseAnimationEnd}
      /> */}

      {/* {snapMuseumPoints.activeThemeIndex != -1 && (
        <>
          {themeComponents.map(
            (Component, index) =>
              index === snapMuseumPoints.activeThemeIndex && (
                <Component key={index} />
              )
          )}
        </>
      )} */}
    </>
  );
}
// useGLTF.preload("../models/EntryRoom/EntryRoom.glb");
