// glbLoader.js
import { useGLTF, useTexture, useProgress } from "@react-three/drei";
import * as THREE from "three";
import { useEffect } from "react";
import { AdditiveBlending, DoubleSide, MeshPhysicalMaterial, TextureLoader } from "three";
import { useLoader } from '@react-three/fiber'
import { useState } from "react";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { stateLoadingScreen } from "../store";
import { useMemo } from "react";



const textureCache = {};
let extensions
let glbPercent = 0
let texturePercent = 0

const whiteTransMaterial = new THREE.MeshBasicMaterial({
   
  color: "white",
  transparent: true,
  opacity: 0.35,

 
});

const whiteEmissiveMaterial = new THREE.MeshBasicMaterial({
   
  color: "white",
  // transparent: true,
  // opacity: 0.35,

 
});

const buttonOriginalMaterial = new THREE.MeshBasicMaterial({
 
  color: "white",
  transparent: true,
  opacity: 0.35,

 
});

const GlbLoader = () => {

  const [modelLoadCount, setModelLoadCount] = useState(0);

 

  const { progress, item } = useProgress();
//  const landscape = useLoader(GLTFLoader,"../models/Museum01/MDR/Landscape.glb");
  // const themeMonitor = useLoader(GLTFLoader,
  //   "../models/EntryRoom/ThemeWalls/ThemeMonitor.glb",incrementLoadCount
  // );

  const [
    IFC_Map_0_AO_Shape,
    IFC_Map_1_AO_Shape,
    IFC_Map_2_AO_Shape,
    IFC_Map_3_AO_Shape,
    IFC_Map_4_AO_Shape,
    IFC_Map_5_AO_Shape,
    IFC_Map_6_AO_Shape,
    IFC_Map_7_AO_Shape,
    IFC_Map_8_AO_Shape,
    IFC_Map_9_AO_Shape,
    IFC_Map_10_AO_Shape,
    IFC_Map_11_AO_Shape,
    IFC_Map_12_AO_Shape,
    CrackerLine_Map_0_AO_Shape,
    CrackerLine_Map_1_AO_Shape,
    CrackerLine_Map_2_AO_Shape,
    CrackerLine_Map_3_AO_Shape,
    CrackerLine_Map_4_AO_Shape,
    CrackerLine_Map_5_AO_Shape,
    CrackerLine_Map_6_AO_Shape,
    CrackerLine_Map_7_AO_Shape,
    CrackerLine_Map_8_AO_Shape,
    CrackerLine_Map_9_AO_Shape,
    CrackerLine_Map_10_AO_Shape,

    Ground_Map_0_AO_Shape,
    Ground_Map_1_AO_Shape,
    Ground_Map_2_AO_Shape,

    ContiMix_Map_0_AO_Shape,
    ContiMix_Map_1_AO_Shape,
    ContiMix_Map_2_AO_Shape,
    ContiMix_Map_3_AO_Shape,
    ContiMix_Map_4_AO_Shape,
    ContiMix_Map_5_AO_Shape,
    ContiMix_Map_6_AO_Shape,

    Dryer_Map_0_AO_Shape,
    Dryer_Map_1_AO_Shape,
    Dryer_Map_2_AO_Shape,
    Dryer_Map_3_AO_Shape,
    Dryer_Map_4_AO_Shape,

    TBand_Map_0_AO_Shape,
    TBand_Map_1_AO_Shape,
    TBand_Map_2_AO_Shape,
    TBand_Map_3_AO_Shape,

  ] = useLoader(TextureLoader,[
    "../overallTextures/chipLine/IFC_Map_0_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_1_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_2_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_3_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_4_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_5_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_6_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_7_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_8_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_9_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_10_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_11_AO_Shape.jpg",
    "../overallTextures/chipLine/IFC_Map_12_AO_Shape.jpg",

    "../overallTextures/chipLine/CrackerLine_Map_0_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_1_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_2_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_3_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_4_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_5_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_6_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_7_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_8_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_9_AO_Shape.jpg",
    "../overallTextures/chipLine/CrackerLine_Map_10_AO_Shape.jpg",

    "../overallTextures/chipLine/Ground_Map_0_AO_Shape.jpg",
    "../overallTextures/chipLine/Ground_Map_1_AO_Shape.jpg",
    "../overallTextures/chipLine/Ground_Map_2_AO_Shape.jpg",

    "../overallTextures/chipLine/ContiMix_Map_0_AO_Shape.jpg",
    "../overallTextures/chipLine/ContiMix_Map_1_AO_Shape.jpg",
    "../overallTextures/chipLine/ContiMix_Map_2_AO_Shape.jpg",
    "../overallTextures/chipLine/ContiMix_Map_3_AO_Shape.jpg",
    "../overallTextures/chipLine/ContiMix_Map_4_AO_Shape.jpg",
    "../overallTextures/chipLine/ContiMix_Map_5_AO_Shape.jpg",
    "../overallTextures/chipLine/ContiMix_Map_6_AO_Shape.jpg",

    "../overallTextures/chipLine/Dryer_Map_0_AO_Shape.jpg",
    "../overallTextures/chipLine/Dryer_Map_1_AO_Shape.jpg",
    "../overallTextures/chipLine/Dryer_Map_2_AO_Shape.jpg",
    "../overallTextures/chipLine/Dryer_Map_3_AO_Shape.jpg",
    "../overallTextures/chipLine/Dryer_Map_4_AO_Shape.jpg",

    "../overallTextures/chipLine/TBand_Map_0_AO_Shape.jpg",
    "../overallTextures/chipLine/TBand_Map_1_AO_Shape.jpg",
    "../overallTextures/chipLine/TBand_Map_2_AO_Shape.jpg",
    "../overallTextures/chipLine/TBand_Map_3_AO_Shape.jpg",


 

   
  ],extensions,(xhr) => {
    texturePercent = (xhr.loaded / xhr.total) * 100
    // console.log(texturePercent + glbPercent )
   
    })







  // imperfectionRoughMap.repeat.set(4, 4);
  // imperfectionRoughMap.wrapS = THREE.RepeatWrapping;
  // imperfectionRoughMap.wrapT = THREE.RepeatWrapping;

  const textures = [
    IFC_Map_0_AO_Shape,
    IFC_Map_1_AO_Shape,
    IFC_Map_2_AO_Shape,
    IFC_Map_3_AO_Shape,
    IFC_Map_4_AO_Shape,
    IFC_Map_5_AO_Shape,
    IFC_Map_6_AO_Shape,
    IFC_Map_7_AO_Shape,
    IFC_Map_8_AO_Shape,
    IFC_Map_9_AO_Shape,
    IFC_Map_10_AO_Shape,
    IFC_Map_11_AO_Shape,
    IFC_Map_12_AO_Shape,
   
  ];


  const texturesCracker = 
  [
    CrackerLine_Map_0_AO_Shape,
    CrackerLine_Map_1_AO_Shape,
    CrackerLine_Map_2_AO_Shape,
    CrackerLine_Map_3_AO_Shape,
    CrackerLine_Map_4_AO_Shape,
    CrackerLine_Map_5_AO_Shape,
    CrackerLine_Map_6_AO_Shape,
    CrackerLine_Map_7_AO_Shape,
    CrackerLine_Map_8_AO_Shape,
    CrackerLine_Map_9_AO_Shape,
    CrackerLine_Map_10_AO_Shape
  ]

  const texturesGround = 
  [
    Ground_Map_0_AO_Shape,
    Ground_Map_1_AO_Shape,
    Ground_Map_2_AO_Shape,

  ]

  const texturesContiMix = 
  [
    ContiMix_Map_0_AO_Shape,
    ContiMix_Map_1_AO_Shape,
    ContiMix_Map_2_AO_Shape,
    ContiMix_Map_3_AO_Shape,
    ContiMix_Map_4_AO_Shape,
    ContiMix_Map_5_AO_Shape,
    ContiMix_Map_6_AO_Shape,

  ]

  const texturesDryer = 
  [
    Dryer_Map_0_AO_Shape,
    Dryer_Map_1_AO_Shape,
    Dryer_Map_2_AO_Shape,
    Dryer_Map_3_AO_Shape,
    Dryer_Map_4_AO_Shape,

  ]

  const texturesTBand = 
  [
    TBand_Map_0_AO_Shape,
    TBand_Map_1_AO_Shape,
    TBand_Map_2_AO_Shape,
    TBand_Map_3_AO_Shape,

  ]


  textures.forEach((texture)=>{
    texture.encoding = THREE.sRGBEncoding;
  texture.flipY = false;

  })

  texturesCracker.forEach((texture)=>{
    texture.encoding = THREE.sRGBEncoding;
  texture.flipY = false;

  })

  texturesGround.forEach((texture)=>{
    texture.encoding = THREE.sRGBEncoding;
  texture.flipY = false;

  })

  
  texturesContiMix.forEach((texture)=>{
    texture.encoding = THREE.sRGBEncoding;
  texture.flipY = false;

  })

  texturesDryer.forEach((texture)=>{
    texture.encoding = THREE.sRGBEncoding;
  texture.flipY = false;

  })

  texturesTBand.forEach((texture)=>{
    texture.encoding = THREE.sRGBEncoding;
  texture.flipY = false;

  })

  const infoTex = useTexture("../overallTextures/distanceScreenMaps/Info.jpg")

  infoTex.encoding = THREE.sRGBEncoding;

  const steelRough = useTexture("../overallTextures/steel_plane_rough.jpg")

  steelRough.encoding = THREE.sRGBEncoding;
  steelRough.repeat.set(2, 2);

  steelRough.wrapS = THREE.RepeatWrapping;
  steelRough.wrapT = THREE.RepeatWrapping;


  const marbleTileColorMap = useTexture("../overallTextures/MarmorFloor/MarbleTile_ColorMap.jpg")
  const marbleTileNormalMap = useTexture("../overallTextures/MarmorFloor/MarbleTile_NormalMap.jpg")
  const marbleTileRoughMap = useTexture("../overallTextures/MarmorFloor/MarbleTile_RoughMap.jpg")

  marbleTileColorMap.encoding = THREE.sRGBEncoding;
  marbleTileColorMap.repeat.set(30, 30);

  marbleTileColorMap.wrapS = THREE.RepeatWrapping;
  marbleTileColorMap.wrapT = THREE.RepeatWrapping;

 
  marbleTileNormalMap.repeat.set(30, 30);

  marbleTileNormalMap.wrapS = THREE.RepeatWrapping;
  marbleTileNormalMap.wrapT = THREE.RepeatWrapping;

  marbleTileRoughMap.repeat.set(30, 30);

  marbleTileRoughMap.wrapS = THREE.RepeatWrapping;
  marbleTileRoughMap.wrapT = THREE.RepeatWrapping;


  const wallAO = useTexture("../overallTextures/studio/studioWallAO.jpg")

  wallAO.encoding = THREE.sRGBEncoding;

  wallAO.flipY = false;


  const floorAO = useTexture("../overallTextures/studio/Floor.jpg")

  floorAO.encoding = THREE.sRGBEncoding;
  floorAO.flipY = false;

  const deckeAO = useTexture("../overallTextures/studio/Decke.jpg")

  deckeAO.encoding = THREE.sRGBEncoding;
  deckeAO.flipY = false;

  const studioWallMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.8,
color: "white",
    map: wallAO,
    // normalMap: marbleTileNormalMap,
    // roughnessMap: marbleTileRoughMap,

    // aoMap: entryRoomWallAOMap,
    //  lightmap: entryRoomWallAOMap,
    //  lightMapIntensity: 50
  });

  const deckeMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.3,

    map: deckeAO,
    // normalMap: marbleTileNormalMap,
    // roughnessMap: marbleTileRoughMap,

    // aoMap: entryRoomWallAOMap,
    //  lightmap: entryRoomWallAOMap,
    //  lightMapIntensity: 50
  });

  const marbleTileMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.3,

    map: marbleTileColorMap,
    normalMap: marbleTileNormalMap,
    roughnessMap: marbleTileRoughMap,
    aoMap: floorAO

    // aoMap: entryRoomWallAOMap,
    //  lightmap: entryRoomWallAOMap,
    //  lightMapIntensity: 50
  });

  const fullBlack = new THREE.MeshBasicMaterial({
    
    color: "black",
    opacity: 0.85,
    transparent: true
    


  });

  const BuehlerBlue = new THREE.MeshStandardMaterial({
    
    color: "#00a89c",
    opacity: 0.85,
 
    


  });

  const blackGlasMaterial = new THREE.MeshStandardMaterial({
    color: 0x3E6064,
  //  metalness: 1,
    roughness: 0.05,
    transparent: true,
    opacity: 0.5,
  });

  const blueGlasMaterial = new THREE.MeshStandardMaterial({
    color: 0x97d3d3,
  //  metalness: 1,
    roughness: 0.05,
    transparent: true,
    opacity: 0.5,
   
  });

  
  const materials = [
    {
      name: "white",
      material: new THREE.MeshStandardMaterial({
        color: 0xf2f2f2,
        roughness: 0.1,
        // metalness: 0.4,
       
        // Add other properties specific to the "white" material here
      })
    },
    {
      name: "plasticblack",
      material: new THREE.MeshStandardMaterial({
        color: 0x242424,
        roughness: 0.3,
     
        // Add other properties specific to the "white" material here
      })
    },
    {
      name: "motor",
      material: new THREE.MeshStandardMaterial({
        color: 0x808080,
        roughness: 0.2,
        
        // metalness: 0.5,
     
        // Add other properties specific to the "white" material here
      })
    },
    {
      name: "conveyor",
      material: new THREE.MeshStandardMaterial({
        color: 0x0099ff,
        roughness: 0.8,
     
        // Add other properties specific to the "white" material here
      })
    },
    {
      name: "inox",
      material: new THREE.MeshStandardMaterial({

        roughness: 1,
        color: "#FFFFFF",
        metalness: 0.9,
        roughnessMap: steelRough,
        side: THREE.FrontSide
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "aluminium",
      material: new THREE.MeshStandardMaterial({

        roughness: 1,
        color: "#ededed",
        metalness: 1,
        roughnessMap: steelRough,
        side: THREE.FrontSide
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "inoxv2",
      material: new THREE.MeshStandardMaterial({

        roughness: 0.2,
        color: "#FFFFFF",
        metalness: 1,
        // roughnessMap: steelRough,
        side: THREE.FrontSide
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "vanishblack",
      material: new THREE.MeshStandardMaterial({

        roughness: 0.7,
        color: "#454545",
        metalness: 0,
        // roughnessMap: steelRough,
        side: THREE.FrontSide,
        
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "plasticred",
      material: new THREE.MeshStandardMaterial({

        roughness: 0.2,
        color: "#ff0000",
        metalness: 0,
        // roughnessMap: steelRough,
        side: THREE.FrontSide
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "plasticyellow",
      material: new THREE.MeshStandardMaterial({

        roughness: 0.2,
        color: "#ffcc00",
        metalness: 0,
        // roughnessMap: steelRough,
        side: THREE.FrontSide
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "plasticgrey",
      material: new THREE.MeshStandardMaterial({

        roughness: 0.2,
        color: "#858585",
        metalness: 0,
        // roughnessMap: steelRough,
        side: THREE.FrontSide
        // Add other properties specific to the "inox" material here
      })
    },

    {
      name: "Ground",
      material: new THREE.MeshStandardMaterial({
// roughnerss: 1,
        // blending: THREE.MultiplyBlending,
      color: "#000000",
     
      transparent: true
    
     
        // Add other properties specific to the "inox" material here
      })
    },
    // Define other materials here
  ];





  const infoStationModel = useMemo(() => useGLTF("../models/EntryRoom/Infopoint.glb", { useDraco: true }), []);
// const entryRoomWallCollider = useGLTF(
//   "../models/EntryRoom/ThemeWalls/ThemeWall_collision.glb"
// );
const [
  entryRoomFloorCollider,
  entryRoomWallCollider,
  playerModel,
  themeMonitor,
  themeWall,
  entryRoomModel,
  pointerModel,
  // salzKristall,
  
  // doorButton, 
  introMonitor,
  playerModelWoman,
  MainMenue,
  products,
  crispAnim
] = useLoader(GLTFLoader,[
  "../models/EntryRoom/EntryRoomFloorCollision.glb",
  "../models/EntryRoom/ThemeWalls/ThemeWall_collision.glb",
 "../models/Player.glb",
  "../models/EntryRoom/ThemeWalls/ThemeMonitor.glb",
  "../models/EntryRoom/ThemeWalls/ThemeWall.glb",
  "../models/EntryRoom/EntryRoom.glb",
  "../models/EntryRoom/Pointer.glb",
  // "../models/EntryRoom/Salzstein.glb",
 
  // "../models/EntryRoom/DoorButton.glb",
  "../models/EntryRoom/IntroMonitor.glb",
  "../models/PlayerWoman.glb",
  "../models/EntryRoom/MainMenue.glb",
   "../models/EntryRoom/Products.glb",
  "../models/EntryRoom/CrispAnim.glb",

],extensions,(xhr) => {
    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
    glbPercent = (xhr.loaded / xhr.total) * 100
    // stateLoadingScreen.basePercent = glbPercent
    stateLoadingScreen.basePercent = glbPercent + texturePercent 
   
  })

  







  // const playerModelWoman = useGLTF("../models/PlayerWoman.glb");
 
 playerModelWoman.scene.name = "playerModelWoman"; // Set a unique name


  playerModel.scene.name = "playerModel"; // Set a unique name







  // imperfectionRoughMap.encoding = THREE.sRGBEncoding;
  // imperfectionRoughMap.flipY = false;
  // imperfectionRoughMap.repeat.set(4, 4);
  // imperfectionRoughMap.wrapS = THREE.RepeatWrapping;
  // imperfectionRoughMap.wrapT = THREE.RepeatWrapping;




  //MATERIAL ASSIGNMENT AND CREATIONS

  //CREATIONS
  //Create Materials





  useEffect(() => {
   
    
    assignMaterialToObjects(entryRoomModel.scene, ["Object021"], marbleTileMaterial);
    assignMaterialToObjects(entryRoomModel.scene, ["Object023","Cylinder001"], studioWallMaterial);
    assignMaterialToObjects(entryRoomModel.scene, ["Object022"], deckeMaterial);
    assignMaterialToObjects(entryRoomModel.scene, ["Object004"], blueGlasMaterial);
    assignMaterialToObjects(entryRoomModel.scene, ["Lights"], whiteEmissiveMaterial);

    assignMaterialToObjects(infoStationModel.scene, ["A_glas_CombinedGeo"], blackGlasMaterial);

    assignMaterialToObjects(MainMenue.scene, ["Rectangle001"], blueGlasMaterial);


    assignMaterialToObjects(introMonitor.scene, ["Rectangle001"], BuehlerBlue);
    // assignMaterialToObjects(infoStationModel.scene, ["White","steher01"], orangeGlossyInfo);
    // assignMaterialToObjects(infoStationModel.scene, ["Material_IFC_Map_11_AO_IFC_white_CombinedGeo","Material_IFC_Map_11_AO_IFC_white_CombinedGeo"], white );
    assignMaterialsToGeometries(infoStationModel.scene, textures, materials);
    assignMaterialsToGeometries_Crackerline(infoStationModel.scene, texturesCracker, materials);
    assignMaterialsToGeometries_Ground(infoStationModel.scene, texturesGround, materials);
    assignMaterialsToGeometries_Dryer(infoStationModel.scene, texturesDryer, materials);
    assignMaterialsToGeometries_ContiMix(infoStationModel.scene, texturesContiMix, materials);
    assignMaterialsToGeometries_TBand(infoStationModel.scene, texturesTBand, materials);




    assignMaterialToObjects(playerModel.scene, ["man_tshirt_shortsWithPockets","head_luke"], fullBlack);
    assignMaterialToObjects(playerModelWoman.scene, ["Mesh001","Mesh","Mesh001_1","Mesh001_2","Mesh001_3","Mesh_1","Mesh_2","Mesh_3","Mesh_4"], fullBlack);
  }, []);


  return {
    entryRoomModel,
    pointerModel,
    playerModel,
    playerModelWoman,
    themeMonitor,
    themeWall,
    entryRoomFloorCollider,
    entryRoomWallCollider,
    infoStationModel,
    // salzKristall,
    MainMenue,
    products,
    infoTex,
    crispAnim,
    steelRough,
   
  
    // doorButton,
    introMonitor,



 

  
  };
};

export default GlbLoader;



function assignMaterialToObjects(scene, objectNames, material) {
  objectNames.forEach((objectName) => {
    const object = scene.getObjectByName(objectName);
    if (object) {
      object.material = material;
    }
  });
}

function assignMaterialsToGeometries(scene, textures, materialSettings) {
  scene.traverse((object) => {
    if (object.isMesh) {
      const geometryName = object.name;
      const matches = geometryName.match(/Material_IFC_Map_(\d+)_AO_(\w+)_CombinedGeo/);

      if (matches) {
        const textureNumber = parseInt(matches[1], 10);
        const materialName = matches[2];

        if (textureNumber >= 0 && textureNumber < textures.length) {
          const clonedMaterial = materialSettings.find((material) => material.name === materialName);

          if (clonedMaterial) {
            const clonedMaterialInstance = clonedMaterial.material.clone();
            clonedMaterialInstance.aoMap = textures[textureNumber];
            // clonedMaterialInstance.map = textures[textureNumber];
            object.material = clonedMaterialInstance;
          }
        }
      }
    }
  });
}

function assignMaterialsToGeometries_Crackerline(scene, textures, materialSettings) {
  scene.traverse((object) => {
    if (object.isMesh) {
      const geometryName = object.name;
      const matches = geometryName.match(/Material_CrackerLine_Map_(\d+)_AO_(\w+)_CombinedGeo/);

      if (matches) {
        const textureNumber = parseInt(matches[1], 10);
        const materialName = matches[2];

        if (textureNumber >= 0 && textureNumber < textures.length ) {
          const clonedMaterial = materialSettings.find((material) => material.name === materialName);

          if (clonedMaterial) {
            const clonedMaterialInstance = clonedMaterial.material.clone();
            clonedMaterialInstance.aoMap = textures[textureNumber];
            // clonedMaterialInstance.map = textures[textureNumber];
            object.material = clonedMaterialInstance;
          }
        }
      }
    }
  });
}


function assignMaterialsToGeometries_ContiMix(scene, textures, materialSettings) {
  scene.traverse((object) => {
    if (object.isMesh) {
      const geometryName = object.name;
      const matches = geometryName.match(/Material_ContiMix_Map_(\d+)_AO_(\w+)_CombinedGeo/);

      if (matches) {
        const textureNumber = parseInt(matches[1], 10);
        const materialName = matches[2];

        if (textureNumber >= 0 && textureNumber < textures.length ) {
          const clonedMaterial = materialSettings.find((material) => material.name === materialName);

          if (clonedMaterial) {
            const clonedMaterialInstance = clonedMaterial.material.clone();
            clonedMaterialInstance.aoMap = textures[textureNumber];
            //clonedMaterialInstance.map = textures[textureNumber];
            object.material = clonedMaterialInstance;
          }
        }
      }
    }
  });
}

function assignMaterialsToGeometries_Dryer(scene, textures, materialSettings) {
  scene.traverse((object) => {
    if (object.isMesh) {
      const geometryName = object.name;
      const matches = geometryName.match(/Material_Dryer_Map_(\d+)_AO_(\w+)_CombinedGeo/);

      if (matches) {
        const textureNumber = parseInt(matches[1], 10);
        const materialName = matches[2];

        if (textureNumber >= 0 && textureNumber < textures.length ) {
          const clonedMaterial = materialSettings.find((material) => material.name === materialName);

          if (clonedMaterial) {
            const clonedMaterialInstance = clonedMaterial.material.clone();
            clonedMaterialInstance.aoMap = textures[textureNumber];
            // clonedMaterialInstance.map = textures[textureNumber];
            object.material = clonedMaterialInstance;
          }
        }
      }
    }
  });
}


function assignMaterialsToGeometries_TBand(scene, textures, materialSettings) {
  scene.traverse((object) => {
    if (object.isMesh) {
      const geometryName = object.name;
      const matches = geometryName.match(/Material_TBand_Map_(\d+)_AO_(\w+)_CombinedGeo/);

      if (matches) {
        const textureNumber = parseInt(matches[1], 10);
        const materialName = matches[2];

        if (textureNumber >= 0 && textureNumber < textures.length ) {
          const clonedMaterial = materialSettings.find((material) => material.name === materialName);

          if (clonedMaterial) {
            const clonedMaterialInstance = clonedMaterial.material.clone();
            clonedMaterialInstance.aoMap = textures[textureNumber];
            // clonedMaterialInstance.map = textures[textureNumber];
            object.material = clonedMaterialInstance;
          }
        }
      }
    }
  });
}

function assignMaterialsToGeometries_Ground(scene, textures, materialSettings) {
  scene.traverse((object) => {
    if (object.isMesh) {
      const geometryName = object.name;
      const matches = geometryName.match(/Material_Ground_Map_(\d+)_AO_(\w+)_CombinedGeo/);

      if (matches) {
        const textureNumber = parseInt(matches[1], 10);
        const materialName = matches[2];

        if (textureNumber >= 0 && textureNumber < textures.length) {
          const clonedMaterial = materialSettings.find((material) => material.name === materialName);

          if (clonedMaterial) {
            const clonedMaterialInstance = clonedMaterial.material.clone();
            // clonedMaterialInstance.aoMap = textures[textureNumber];
            clonedMaterialInstance.alphaMap = textures[textureNumber];
            object.material = clonedMaterialInstance;
          }
        }
      }
    }
  });
}

// function assignMaterialsToGeometries(scene, textures, materialSettings) {
//   scene.traverse((object) => {
//     if (object.isMesh) {
//       const geometryName = object.name;
//       let textureNumber = -1; // Default value in case no texture number is found

//       // Extract the texture number if present
//       const textureNumberMatches = geometryName.match(/Material_IFC_Map_(\d+)_AO_IFC_(\w+)_CombinedGeo/);
//       if (textureNumberMatches) {
//         textureNumber = parseInt(textureNumberMatches[1], 10);
//       }

//       // Check if any part of the material name is included in the geometry name
//       const matchingMaterial = materialSettings.find((material) => {
//         const materialName = material.name.toLowerCase(); // Convert to lowercase for case-insensitive match
//         return geometryName.toLowerCase().includes(materialName);
//       });

//       if (matchingMaterial && textureNumber >= 0 && textureNumber < textures.length) {
//         const clonedMaterialInstance = matchingMaterial.material.clone();
//         clonedMaterialInstance.map = textures[textureNumber];
//         object.material = clonedMaterialInstance;
//       }
//     }
//   });
// }
// useLoader.preload(GLTFLoader, [
//   "../models/EntryRoom/ThemeWalls/ThemeWall_collision.glb",
//   "../models/EntryRoom/EntryRoomFloorCollision.glb",
//   "../models/Player.glb",
//   "../models/EntryRoom/ThemeWalls/ThemeMonitor.glb",
//   "../models/EntryRoom/ThemeWalls/ThemeWall.glb",
//   "../models/EntryRoom/EntryRoom.glb",
//   "../models/EntryRoom/Pointer.glb",
//   "../models/EntryRoom/Salzstein.glb",
//   "../models/EntryRoom/Infopoint.glb",
//   "../models/EntryRoom/DoorButton.glb"] /* extensions */)

  // useLoader.preload(TextureLoader, [
  //   "../overallTextures/distanceScreenMaps/Handelswege.jpg",
  //   "../overallTextures/distanceScreenMaps/MDR.jpg",
  //   "../overallTextures/distanceScreenMaps/Salzabbau.jpg",
  //   "../overallTextures/distanceScreenMaps/Klima.jpg",
  //   "../overallTextures/distanceScreenMaps/Menschen.jpg",
  //   "../overallTextures/distanceScreenMaps/Holzarbeit.jpg",
  //   "../overallTextures/Information.png",
  //   "../overallTextures/AO/entryRoomFloorAO.jpg",
  //   "../overallTextures/AO/entryRoomInfoAO.jpg",
  //   "../overallTextures/AO/entryRoomWallAO.jpg",
  //   "../overallTextures/AO/entryRoomDoorAOMap.jpg",
  //   "../overallTextures/T_Imperfections_Wipe_Mask_rough.jpg",
  //   "../overallTextures/salt/salt_ColorMap.jpg",
  //   "../overallTextures/salt/salt_NormalMap.jpg",
  //   "../overallTextures/salt/salt_RoughMap.jpg",
  //   "../overallTextures/Gradient_Glow.jpg",
  //   "../overallTextures/salzstein/SalzStein_ColorMap.jpg",
  //   "../overallTextures/salzstein/SalzStein_NormalMap.jpg",
  //   "../overallTextures/WallPutz/WallPutz_ColorMap.jpg",
  //   "../overallTextures/WallPutz/WallPutz_NormalMap.jpg",
  //   "../overallTextures/WallPutz/WallPutz_RoughMap.jpg",
  //   "../overallTextures/interactiveArea.png",
  //   "../overallTextures/ConcreteWallB/ConcreteWallB_ColorMap.jpg",
  //   "../overallTextures/ConcreteWallB/ConcreteWallB_NormalMap.jpg",
  //   "../overallTextures/ConcreteWallB/ConcreteWallB_RoughMap.jpg",
  //   "../overallTextures/ConcreteWallB/ConcreteWallB_AOMap.jpg",
  //   "../overallTextures/water/Water_ColorMap.jpg",
  //   "../overallTextures/water/WaterNormalTile.jpg"
  // ] /* extensions */)