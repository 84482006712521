import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import {
  stateTouchCircleLook,
  statePlayerPositionS,
  statePlayerSwitch,
  stateOrbitLocation
} from "../store";

import { useRef, useEffect, useState } from "react";
import { usePlayerPosition } from "./PlayerPositionContext";
// import { useState } from "react"
let diff = 0;



const CameraControlsCharSwitch = ({}) => {
  const { camTarget, playerQuat, playerPosition } = usePlayerPosition();


  const snapCharacterSwitch = useSnapshot(statePlayerSwitch);

  const orbitClicked = snapCharacterSwitch.charSwitchClicked;

  const camera = useThree((state) => state.camera);

  const initialCameraPosition = useRef(new THREE.Vector3());

  const [block, setBlock] = useState(true);
  const offsetDistance = -30;

  const forwardVector = new THREE.Vector3(0, 0, -1);
  const offsetVector = new THREE.Vector3(0, 0, 0);
  const camPosOffset = new THREE.Vector3(0, 13, 0);

  const captureCameraPosition = new THREE.Vector3()
  const captureCameraQuat = new THREE.Quaternion()
  useEffect(() => {
    
    if (orbitClicked) {
      const cameraPosClone =camera.position.clone();
      const cameraQuatClone = camera.quaternion.clone();
      captureCameraPosition.copy(cameraPosClone)
      captureCameraQuat.copy(cameraQuatClone)

      const playerPosClone = playerPosition.clone();
      const playerQuatClone = playerQuat.clone();
      forwardVector.applyQuaternion(playerQuatClone);
      offsetVector.copy(forwardVector.clone().multiplyScalar(offsetDistance));
      playerPosClone.add(camPosOffset);
      initialCameraPosition.current.copy(playerPosClone).add(offsetVector);
      statePlayerPositionS.playeractive = false;
    }
  }, [orbitClicked]);

  useFrame(({ scene }) => {
    if (orbitClicked && block) {
   
      stateOrbitLocation.lerpRuns = true
      camera.position.lerp(initialCameraPosition.current, 0.05);
      camera.quaternion.slerp(playerQuat, 0.05);
    

      const diffPosition = camera.position.clone().sub(initialCameraPosition.current).length();
      if (diffPosition < 0.5) {
        setBlock(false);
        statePlayerPositionS.playeractive =false;
        statePlayerSwitch.characterUiOn = true
        stateTouchCircleLook.JoystickLookVisible = false
        stateOrbitLocation.lerpRuns = false
      }
    } else if (!orbitClicked && !block) {

  
    //  camera.position.lerp(captureCameraPosition, 0.05);
    //  camera.quaternion.slerp(playerQuat, 0.05);
 
    setBlock(true);
        statePlayerPositionS.playeractive = true;
        statePlayerSwitch.characterUiOn = false
        stateTouchCircleLook.JoystickLookVisible = true

        
      diff = camera.position
        .clone()
        .sub(captureCameraPosition)
        .length();
      if (diff < 0.5) {
        // stateOrbitLocation.shouldUpdate = true;
        // stateOrbitLocation.lerpRuns = false
        
       
      }
    }
  });

  return <>{/* <OrbitControls ref={orbitRef} target={camTarget} /> */}</>;
};

export default CameraControlsCharSwitch;
