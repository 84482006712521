import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { stateInArea } from "../store";
import { usePlayerPosition } from "./PlayerPositionContext";
import { useSnapshot } from "valtio";
import { useLayoutEffect } from "react";

const modelPosition = new THREE.Vector3()

let distanceSquared = 0

let newPlayerInsideArea

function CheckInArea(props) {
  const { squareA, squareB, position, index, setAreaActive } = props;
  const thresholdSquared = squareA * squareB;

  const [playerInsideArea, setPlayerInsideArea] = useState();
  const { playerPosition } = usePlayerPosition();
  const snapInArea = useSnapshot(stateInArea)

  
  // Use a separate useEffect to handle the first load
  useEffect(() => {
    // This code will run only on the first load of the component
    // You can perform any initialization here if needed

    return () => {
      // Cleanup code, if necessary
    };
  }, []);
 
  useEffect(() => {
  
   
 
    setAreaActive(playerInsideArea);
   


  stateInArea.inAreaIndex = index;
  stateInArea.inArea = playerInsideArea;

  


  }, [playerInsideArea]);

  useFrame(() => {
    if (playerPosition instanceof THREE.Vector3) {
      modelPosition.set(...position);

      distanceSquared = playerPosition.distanceToSquared(modelPosition);
    
      newPlayerInsideArea = distanceSquared < thresholdSquared;

      if (newPlayerInsideArea !== playerInsideArea) {
        setPlayerInsideArea(newPlayerInsideArea);
       
      }
    }
  });

  return;
}

export default CheckInArea;
