
import GlbLoader from "../Utils/GlbLoader";

import { RigidBody,} from "@react-three/rapier"


export default function EntryRoomCollider() {

  const {entryRoomFloorCollider,} = GlbLoader()


  return (
 
<RigidBody type= "fixed" colliders="cuboid"
includeInvisible
>
      <primitive object={entryRoomFloorCollider.scene} scale={1}  visible={false}/>
      </RigidBody>

      

)}
// useGLTF.preload("../models/EntryRoom/EntryRoom.glb");