import "./HeaderStyle.css";
import { motion } from "framer-motion";
import { statePlayerSwitch } from "../store";
import { useState } from "react";
import { statePlayerPositionS } from "../store";
import { stateOrbitLocation,  } from "../store";
import { useSnapshot } from "valtio";

export default function Header() {
  return <HeaderKon />;
}

function HeaderKon() {
  const [charClicked, setCharClicked] = useState(false);
  const [audioClicked, setAudioClicked] = useState(true);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFp, setIsFp] = useState(false);
  const snapOrbitLoaction = useSnapshot(stateOrbitLocation)
  const snapPlayerSwitch = useSnapshot(statePlayerSwitch)

  function handleCharClick() {
    if (!statePlayerSwitch.charSwitchClicked && !stateOrbitLocation.lerpRuns ) {
      statePlayerSwitch.charSwitchClicked = true;
    } else if(!stateOrbitLocation.lerpRuns) {
      statePlayerSwitch.charSwitchClicked = false;
    }

    setCharClicked(!charClicked);
  }

  function handleFPClick() {
    setIsFp(!isFp);

    if(!isFp){

      statePlayerPositionS.fpPlayer=true
    }
    else{
      statePlayerPositionS.fpPlayer=false
    }
  }

  function handleAudioClick() {
  

    setAudioClicked(!audioClicked);
  }

  function toggleFullscreen() {
    if (!isFullscreen) {
      // Request fullscreen
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen); // Toggle fullscreen state
  }

  return (
    <motion.div
      className="container"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", duration: 1.8, delay: 3 }}
    >
      <header className="header">
        {/* Logo */}
        <div className="logoContainerHUD">
          <img
            className="museumIconHUD"
            src="../UI/IconMsueum.svg"
            alt="Museum Icon"
          />
          {/* <div className="logoHUD">BÜHLER</div>
          <div className="logoSentenceHUD">SOLUTIONS NET ONLINE</div> */}
        </div>

        {/* Sidemenu */}
        <div className="sideMenuContainer">
          <div className={`circleHeader ${audioClicked ? "clicked" : ""}`}>
            <img
              className="headerButton"
              src="UI/AudioButton2_.png"
              alt="Audio"
              onClick={handleAudioClick}
            />
          </div>

        { !snapOrbitLoaction.orbitClicked && <div
            className={`circleHeader ${charClicked ? "clicked" : ""}`}
            onClick={handleCharClick}
          >
            <img
              className="headerButton"
              src="UI/CharButton2_.png"
              alt="Character"
            />
          </div>}
          <div
            className={`circleHeader ${isFullscreen ? "clicked" : ""}`}
            onClick={toggleFullscreen}
          >
            <img
              className="headerButton"
              src="UI/FullscreenIcon_.png"
              alt="FullScreen"
            />
          </div>
      {  !snapOrbitLoaction.orbitClicked && !snapPlayerSwitch.charSwitchClicked &&  <div
            className={`circleHeader ${isFp ? "clicked" : ""}`}
            onClick={handleFPClick}
          >
            <img
              className="headerButton"
              src="UI/FPButton_.png"
              alt="FullScreen"
            />
          </div>}
        </div>
      </header>
    </motion.div>
  );
}
