import { proxy } from "valtio";
import * as THREE from "three";


const stateInArea = proxy ({
    inArea: false,
    inAreaIndex: "" 
})

const stateRoomDisplay = proxy({
    roomDisplay: true,
   
   
})

const stateOrbitLocation = proxy({
    orbitClicked: false,
    CameraPos: new THREE.Vector3(0,0,0),
    TargetPos: new THREE.Vector3 (0,0,0),
    shouldUpdate: true,
    lerpRuns: false


})

const statePlayerPositionS = proxy ({
// playerPositionS: new THREE.Vector3(0,0,0),
playeractive: true,
playerCameraTargetPosition: new THREE.Vector3(0,0,0),
fpPlayer: false


})

const stateUiDisplay = proxy({
    storyTextMatch: false,
    orbitData: false,
    openInfoWindow: false,
   
})

const stateDevice = proxy({
    isMobile: false,
    isTablet: false
})

const stateTouchCircle = proxy(
    {
        x: 0,
        y: 0,
       joystickMove: false
    }
) 

const stateTouchCircleLook = proxy(
    {
        x: 0,
        y: 0,
        OrbitOn:false,
        // JoystickLookOn:false,
        JoystickLookVisible: true
       
    }
) 

const stateWallPush = proxy(
    {
        activeThemeIndex: -1
    }
)
const statedynamicLight = proxy(
    {
        videoLight: false
    }
)

const stateLoadingScreen = proxy(

    {
        loadingScreenAnimate: true,
       lastInfoWindowClick: false,
        baseLoaded:false,
        basePercent: 0
    }
)

const stateIframeWindow = proxy(

    {
        showIframe: false,
        showIframeScetch: false,
        src: "",
        renderCanvas: false,
        showVideoPlane: false,
        VideoSrc:""
    }
)

const statePlayerSwitch = proxy ({
   playerWoman:false,
   charSwitchClicked: false,
   characterUiOn: false
    
    
    })

  const stateMDR = proxy ({
    museaumActive: ""
     
     
     })


export {
    stateInArea,
    statePlayerPositionS,
    stateOrbitLocation,
    stateUiDisplay,
    stateDevice,
    stateTouchCircle,
    stateWallPush,
statedynamicLight,
stateLoadingScreen,
stateIframeWindow,
stateTouchCircleLook,
statePlayerSwitch,
stateMDR,
stateRoomDisplay


}