import "./ThemeMonitor.css";

import { useEffect, useState, useRef, } from "react";
import { Bvh } from "@react-three/drei";
import { useMemo } from "react";
import GlbLoader from "../Utils/GlbLoader";
import { meshBounds } from "@react-three/drei";





export default function Infostation() {

 
 
;
const  {infoStationModel } =  GlbLoader();






  const infoStationModelRef = useRef();

  

  return (
    <>

      <InfoStationKon
       
      
    
      />

      <primitive
      raycast={meshBounds}
        castShadow
        receiveShadow
        position={[0,5.5,0]}
        ref={infoStationModelRef}
        object={infoStationModel.scene}
      />
    
    </>
  );
}

function InfoStationKon() {
 






  return (
    <>
   
      
    
     
    

      {/* <Sparkles scale = {[40,200,40]} position = {[0,40,0]} speed = {2} size = {280} noise={5} opacity = {0.5}/>  */}

    





    </>
  );
}




