import "./Joystickb.css";
import { stateTouchCircleLook } from "../store";
import { stateDevice } from "../store";
import { useSnapshot } from "valtio";
import { useEffect } from "react";
import { useState } from "react";

import { Joystick } from "react-joystick-component";


// var joySize = 150
// var joysizeThumb = 70


export default function JoystickLook() {

  const [joySize, setjoySize] = useState()
  const [joysizeThumb, setjoysizeThumb] = useState()


  const snapIsMobile = useSnapshot(stateDevice)
  useEffect(()=>{
    if (snapIsMobile.isMobile  && !snapIsMobile.isTablet)
    {
      setjoySize(100)
      setjoysizeThumb(40)

    }
    else{
      setjoySize(150)
      setjoysizeThumb(70)
    }
    
      },[snapIsMobile.isMobile])



  const handleMove = (e) => {
 
  

  
   
    stateTouchCircleLook.x = e.x *  0.5;
    stateTouchCircleLook.y = - e.y  + -0.75
    stateTouchCircleLook.OrbitOn = true
    stateTouchCircleLook.JoystickLookOn = true

    // console.log( stateTouchCircleLook.y)
  };
  const handleStop = (e) => {
 
    
    stateTouchCircleLook.x = 0;
    stateTouchCircleLook.y = 0;
    stateTouchCircleLook.OrbitOn = false

  };

 
  return (
    <div className="joystickRight" >
    
      <Joystick
        size={joySize}
        baseColor="red"
        stickColor="blue"
        stickSize={joysizeThumb}
        move={handleMove}
        stop={handleStop}
        baseImage="./UI/JoystickBackground.png"
        stickImage="./UI/JoystickThumb.png"
      // throttle = {300}
      />
    </div>
  );
}
