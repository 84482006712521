import "./ThemeMonitor.css";
import { Plane, Text } from "@react-three/drei";
import { Clone, Html } from "@react-three/drei";
import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import stateThemeMonitor from "./states/stateThemeMonitor";
import { stateInArea } from "../store";
import CheckInArea from "../Utils/CheckInArea.jsx";
import { useSnapshot } from "valtio";
import { useMemo } from "react";

const mPointsLength = stateThemeMonitor.length;

export default function ThemeMonitor() {
  
  const [clones, setClones] = useState([]);

  const clonesData = useMemo(() => {
    // Calculate the rotation for each clone based on its index (x)
    return [...Array(mPointsLength).keys()].map((monitor, x) => {
      const {
        x: rotationX,
        y: rotationY,
        z: rotationZ,
      } = stateThemeMonitor[x].Rotation;
  
      const rotationInRadians = [
        THREE.MathUtils.degToRad(rotationX),
        THREE.MathUtils.degToRad(-rotationZ - 90),
        THREE.MathUtils.degToRad(rotationY),
      ];
  
      return {
        key: x,
        position: [...stateThemeMonitor[x].Position],
        rotation: rotationInRadians,
        index: [...stateThemeMonitor[x].Id],
      };
    });
  }, [mPointsLength, stateThemeMonitor]);
  
  useEffect(() => {
    setClones(clonesData);
  }, []);

  return (
    <>
      {clones.map((clone) => (
        <ThemeMonitorKon
          key={clone.key}
          scale={1}
          position={clone.position}
          rotation={clone.rotation}
          index={stateThemeMonitor[clone.key].Id}
        ></ThemeMonitorKon>
      ))}
    </>
  );
}

function ThemeMonitorKon({ position, rotation, index }) {
  const modelRef = useRef();
  const { themeMonitor } = GlbLoader();
  const [iframeActive, setIframeActive] = useState(false);
  const snapPlayerInsideArea = useSnapshot(stateInArea);
  const playerInsideArea = snapPlayerInsideArea.inArea;
  const [areaActive, setAreaActive] = useState(false);
  const screenPositionStatic = new THREE.Vector3(0,0, 0);
  const [hovered, setHovered] = useState(false);

  const rotationInRadians = [
    THREE.MathUtils.degToRad(0),
    THREE.MathUtils.degToRad(0),
    THREE.MathUtils.degToRad(0),
  ];
  const titelFont = {
    font: "/Fonts/Roboto-Regular-webfont.woff",
    // font: "/Fonts/Roboto-Regular-webfont.woff",

    letterSpacing: -0.05,
    lineHeight: 1,
    "material-toneMapped": false,
    fontSize: 2,
    anchorX: "left",
    anchorY: "top",
    characters: "abcdefghijklmnopqrstuvwxyz0123456789!",
  };

  const {
    infoTex,

  } = GlbLoader();

  const textures = {
    infoTex: infoTex,
   
  };

  const handlePlaneClick = () => {
    if (areaActive) {
      setIframeActive(true);
    }
  };

  useEffect(() => {
    // Call the callback function with the updated playerInsideArea value
    if (!playerInsideArea) {
      setIframeActive(false);
     
    }
  }, [playerInsideArea]);


  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  const handleMouseOver = () => {
    setHovered(true);

  
}

   

    const handleMouseOut = () => {
      setHovered(false);
  
  
    }

  return (
    <>
      <Clone
        scale={1}
        position={position}
        rotation={rotation}
        object={themeMonitor.scene}
        ref={modelRef}
        dispose = {null}
        
        //   onPointerOver={(e) => setHover(true)}
        //   onPointerOut={(e) => setHover(false)}
        // onPointerDown= {onPointerDown}
      >
        {iframeActive ? (
          <Html
            transform
            wrapperClass="htmlScreen"
            // distanceFactor={1.17}
            distanceFactor={2.45}
            occlude="blending"
            position={screenPositionStatic}
            rotation={rotationInRadians}
            zIndexRange={[2, 0]}
            scale={[9, 9, 9]}
            dispose = {null}
          >
            <iframe
              src="https://www.youtube.com/embed/_uf5Y9e8WVI?rel=0&autoplay=1&amp&;controls=0"
              style={{ userSelect: "none" }}
              dispose = {null}
              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
            />
          </Html>
        ) : (
          <Plane
            args={[47, 26]}
            position={screenPositionStatic}
            rotation={rotationInRadians}
            onClick={handlePlaneClick}
            dispose = {null}
            onPointerEnter={handleMouseOver}
        onPointerLeave={handleMouseOut}
          >
            {areaActive ? (
              <Text
                position={[-3, -8.6, 1]}
                rotation={[0, 0, 0]}
                color={"white"}
                
                {...titelFont}
               
              >
                {"CLICK"}
               
              </Text>
            ) : null}

            <meshStandardMaterial
              map={textures.infoTex}
              // toneMapped={false}
              //  encoding={SRGBColorSpace}
              roughness={0}
              metalness={0}
              needsUpdate={true}
              toneMapped={false}
              dispose = {null}
              // color= {"red"}
            />
          </Plane>
        )}
      </Clone>
      <CheckInArea
        squareA={80}
        squareB={80}
        position={position}
        index={index}
        setAreaActive={setAreaActive}
      />
    </>
  );
}
// useGLTF.preload("../models/EntryRoom/Pointer.glb");
