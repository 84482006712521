
import { Plane, useGLTF, } from "@react-three/drei";

import { useEffect, useRef, useState, } from "react";

import { stateInArea } from "../store";

import { useSnapshot } from "valtio";
import * as THREE from "three";
import { stateIframeWindow } from "../store";
import { useFrame } from "@react-three/fiber";
import { Color } from "three";






export default function FocusPunkt({ position, rotation, index,id }) {

  const [hovered, setHovered] = useState(false);
  const [iframeActive, setIframeActive] = useState(false);
  const snapPlayerInsideArea = useSnapshot(stateInArea);
  const ref = useRef()
  const color = new THREE.Color( 0x0CE8E2 );
  const black = new THREE.Color( 0x000000  );

const fp = useGLTF("../models/FP.glb")


    
 
  useFrame(()=>{

    ref.current.rotation.y += 0.005; 


  })


  const handleMouseOver = () => {
    setHovered(true);
    ref.current.traverse((node) => {
        if (node.isMesh ) {
            // node.material.emissive = "#33FFF9"
              node.material.emissive.set(color)
            
            };
          });
  
}

   

    const handleMouseOut = () => {
      setHovered(false);
      ref.current.traverse((node) => {
        if (node.isMesh ) {
            // node.material.emissive = "#33FFF9"
              node.material.emissive.set(black)
            
            };
          });
  
  
    }
  

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])



  const handlePlaneClick = () => {
  
    stateIframeWindow.showIframe = true;
    stateIframeWindow.src = "./FocusOven/index.html";
    stateIframeWindow.renderCanvas = false;

  };



  return (
    <>
    
          <primitive
         object = {fp.scene}
         scale= {10}
         ref={ref}
             position={[0,40,-8]}
            // rotation={rotation}
            onClick={handlePlaneClick}
            onPointerEnter={handleMouseOver}
        onPointerLeave={handleMouseOut}
            dispose = {null}
          >
          

          </primitive>
       
   

    </>
  );
}
// useGLTF.preload("../models/EntryRoom/Pointer.glb");
