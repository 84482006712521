import * as THREE from 'three'

export default [
{
	Id: 'InfoPointMonitor001',
	Titel: 'InfoPointMonitor001',
	Position: new THREE.Vector3 (302.181, 1, 0),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'InfoPointMonitor002',
	Titel: 'InfoPointMonitor002',
	Position: new THREE.Vector3 (-24.613, 1,0),
	Rotation: new THREE.Vector3 (0.0,0.0,0)
},
{
	Id: 'InfoPointMonitor003',
	Titel: 'InfoPointMonitor003',
	Position: new THREE.Vector3 (-323.884, 1, 0),
	Rotation: new THREE.Vector3 (0.0,0.0,0)
},

]